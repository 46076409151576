import React, { useRef } from "react";
import { Form, Button, Accordion, Card } from "react-bootstrap";
import emailjs from "emailjs-com";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../config";
const TheQuickEmail = ({ from, placeholder, buttonText, accordionText }) => {
  const accordionBTN = useRef(null);

  //////////Function for EmailJS//////////
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    accordionBTN.current.click();
    alert("Message Sent");
  }

  return (
    <>
      <Accordion style={{ margin: "3em 0" }}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0" ref={accordionBTN}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
            &nbsp; {accordionText || "Reply this message?"}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body
              style={{
                backgroundColor: "rgba(4, 1, 130, .2)",
                border: "1px solid rgba(4, 1, 130, .5)",
              }}
            >
              <Form className="" onSubmit={sendEmail} style={{ width: "100%" }}>
                <Form.Control
                  name="user_user"
                  as="input"
                  value={from}
                  style={{ display: "none" }}
                />
                <Form.Control
                  name="message"
                  as="textarea"
                  rows={6}
                  placeholder={placeholder}
                  required
                />

                <p class=""></p>
                <Button className="sendButton" type="submit" value="Send">
                  {buttonText || "Well, send it!"}
                </Button>
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default TheQuickEmail;
