import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Container,
  Row,
  Col,
  Modal,
  Card,
  OverlayTrigger,
  Popover,
  Accordion,
} from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import Bar from "./Bar";
import Footer from "./Footer";
import "./hailu.css";

// import data from './data.json';
// const arrayData = data[0].jsonObject.bsArray;

import jsonDataFile from "./data.json";

const jsonData = jsonDataFile[0].firstArrayOfJsonDataFile;

const Hailu = () => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <Card.Img variant="top" src={item.pictureUrl} />

            <CardHeader>
              <h5 style={{ textAlign: "left" }}>Backstory:</h5>
            </CardHeader>

            <CardBody>
              <CardText style={{ textAlign: "left" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //////////////////////

  const firstParagraph = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content style={{ background: "azure" }}>
        <strong>
          Response
          <br />
          <br />
          Suspendisse ut ipsum ut augue condimentum finibus eu in mauris. Proin
          tortor purus, aliquam a magna at, accumsan condimentum eros. Proin
          pulvinar purus eget nibh egestas, vel vestibulum nisi vehicula. Aenean
          vel convallis ex. Duis blandit diam ipsum, condimentum cursus justo
          ullamcorper id. Pellentesque scelerisque dictum lorem, id sollicitudin
          nibh vulputate quis. Praesent quis gravida velit.
        </strong>
      </Popover.Content>
    </Popover>
  );

  const secondParagraph = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content style={{ background: "azure" }}>
        <strong>
          Response <strong>two</strong>.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          iaculis et justo at bibendum. Class aptent taciti sociosqu ad litora
          torquent per conubia nostra, per inceptos himenaeos. Aliquam et dui
          ipsum. Integer tristique, quam in faucibus convallis, nulla leo
          iaculis magna, sit amet mollis nulla eros in orci. Fusce dapibus eget
          orci rutrum vulputate. Etiam eu dolor quis elit egestas gravida.
          Suspendisse faucibus lorem enim, non elementum mauris dapibus ut.
        </strong>
      </Popover.Content>
    </Popover>
  );

  return (
    <React.Fragment>
      <Bar />
      <Container id="Hailu">
        <br />
        <h3>Dymamic information to share</h3>
        <hr />
        <br />
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <i class="fas fa-briefcase"></i> &nbsp; Projects
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div>
                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://smithlab.mit.edu/">
                          Smithlab.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br />
                          - Softern border on cards <br />- Click on Hero
                          redirects same place
                        </li>
                        <li>
                          {" "}
                          <strong>[Research]</strong> <br />
                          Add space between bullet points
                        </li>
                        <li>
                          {" "}
                          <strong>[Members]</strong> <br />
                          Merge PI and Administration
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Publications]</strong> <br />
                          - Links to open in new tab <br />- Separate 2021-2016
                          publications in dropdown
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://prathergroup.mit.edu/">
                          prathergroup.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br />
                          - Subtitle under hero banner
                          <br />
                          - Add news to sidebar/widget
                          <br />
                        </li>
                        <li>
                          {" "}
                          <strong>[Members > Current ]</strong> <br />
                          - Merge PostDoc Associates (1) with Students
                          <br />- Write "PI" instead of "Principal Investigator"
                          <br />- Spacing between undergraduate students
                        </li>
                        <li>
                          {" "}
                          <strong>[Members > Former ]</strong> <br />
                          Spacing between items/alumni
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Research]</strong> <br />
                          Add bullets for "three main categories"
                        </li>{" "}
                        <li>
                          <strong>[News]</strong> <br />
                          Make pictures and content display flex + correct
                          doubled title
                        </li>
                        <li>
                          <strong>[Resources] </strong>
                          <br />
                          Display two-liners flex to avoid unnecessary
                          white-space
                        </li>
                        <li>
                          <strong>[Contact Us] </strong>
                          <br />
                          Display professors in flex format to avoid unnessary
                          white-space (jensenlab example)
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://hattongroup.mit.edu/">
                          hattongroup.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br />- Subtitle under hero
                          banner
                        </li>
                        <li>
                          {" "}
                          <strong>[Members]</strong> <br />- Grad Students: Get
                          Kai-Jher Tan's picture <br />- Alumni: Division
                          between "Former [x]" & "Graduate Students"
                          <br />- Alumni: Make soft table
                        </li>
                        <li>
                          {" "}
                          <strong>[Publications]</strong> <br />
                          Many lack hyperlink to study (is this available?)
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[News]</strong> <br />
                          Flex a few pictures with content
                        </li>
                        <li>
                          {" "}
                          <strong>[Contact Us]</strong> <br />
                          Display professors in flex format to avoid unnessary
                          white-space
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://myersongroup.mit.edu/">
                          myersongroup.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br /> Adding{" "}
                          <strong>[Our values]</strong> here would be great.
                          Jensen Lab is great example :)
                        </li>
                        <li>
                          {" "}
                          <strong>[Publications]</strong> <br />
                          Links to be integrated in text
                        </li>
                        <li>
                          {" "}
                          <strong>[Research]</strong> <br />
                          Anchor tags allow for title to be shown on
                          click/scroll
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Seminars]</strong> <br />
                          2018 seem a little outdated. Any new ones?
                        </li>
                        <li>
                          {" "}
                          <strong>[Safety & Training]</strong> <br />
                          Merge with "For members"?
                        </li>
                        <li>
                          {" "}
                          <strong>[Contact Us]</strong> <br />
                          To potentially add
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://jensenlab.mit.edu/">
                          jensenlab.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Research > Nanomaterials]</strong> <br />
                          "Areas of focus" list needs capitalization
                        </li>
                        <li>
                          {" "}
                          <strong>[Publications]</strong> <br />
                          No links{" "}
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[News]</strong> <br />
                          Date frist, Title/News item 2nd
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://langerlab.mit.edu">
                          langerlab.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br />
                          Add spacing under "Research Overview"
                        </li>
                        <li>
                          {" "}
                          <strong>[People]</strong> <br />- Simple click goes to{" "}
                          <strong>[Home]</strong>
                          <br />- <strong>[People > Members]</strong> is a
                          gallery (make gallery) <br />
                          - "Members Only Site" link is broken
                          <br />
                          <strong>[Publications]</strong> <br />
                          - No links
                          <br />
                          -2020-<strong>present</strong>
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[News]</strong> <br />
                          2016 items lacks link{" "}
                        </li>
                        <li>
                          {" "}
                          <strong>[US-JAPAN SYMPOSIUM]</strong> <br />
                          Paragraph text larger than necessary + same color as
                          header
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://distap.mit.edu/">
                          distap.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Home]</strong> <br />
                          - Great linking display :) <br />- Improve card's
                          images
                        </li>
                        <li>
                          {" "}
                          <strong>[Research]</strong> <br />
                          - Make a full clickable cards <br />- Flex content
                          under cards
                        </li>
                        <li>
                          {" "}
                          <strong>[People]</strong> <br />
                          Merge the 1 collabroator with "staff"?
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Publications]</strong> <br />
                          - Some are missing links <br />- Add small dividing
                          (centered) line
                        </li>
                        <li>
                          {" "}
                          <strong>[News]</strong> <br />
                          Flex picture with text whenever applies
                        </li>
                        <li>
                          {" "}
                          <strong>[Contact Us]</strong> <br />
                          Lacks a name, a phone number and an email (despite
                          footer)
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1%",
                      border: "1px solid #d3d3d3",
                      borderRadius: "5px",
                      marginBottom: "1%",
                    }}
                  >
                    <p style={{ fontWeight: 400 }}>
                      <strong>
                        <a target="_blank" href="https://mlpds.mit.edu/">
                          mlpds.mit.edu
                        </a>
                      </strong>
                    </p>
                    <div className="fixes-list" style={{ display: "flex" }}>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Lorem ipsum, dolor sit amet consectetur adipisicing
                          elit. Atque saepe asperiores illo, fuga libero omnis!
                        </li>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Autem, rem! Voluptate, mollitia quia veniam
                          perferendis ratione laudantium ipsam maxime asperiores
                          dolor ipsa impedit.{" "}
                        </li>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Officiis illum cumque animi veniam facilis
                          dolore earum?
                        </li>
                      </ul>
                      <ul style={{ marginLeft: "1em" }}>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Autem, rem! Voluptate, mollitia quia veniam
                          perferendis ratione laudantium ipsam maxime asperiores
                          dolor ipsa impedit.{" "}
                        </li>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit.
                        </li>
                        <li>
                          {" "}
                          <strong>[Page]</strong> <br />
                          Autem, rem! Voluptate, mollitia quia veniam
                          perferendis ratione laudantium ipsam maxime asperiores
                          dolor ipsa impedit.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://hsikeslab.mit.edu">
                        hsikeslab.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://olsenlab.mit.edu">
                        olsenlab.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a href="https://d3batt.mit.edu">d3batt.mit.edu</a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a href="https://troutgroup.mit.edu">
                        troutgroup.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://cent.mit.edu">
                        cent.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a href="https://hammondlab.mit.edu">
                        hammondlab.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://srg.mit.edu">
                        srg.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://furstlab.mit.edu">
                        furstlab.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://gallowaylab.mit.edu">
                        gallowaylab.mit.edu
                      </a>
                    </strong>
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    <strong>
                      <a target="_blank" href="https://rutledgegroup.mit.edu">
                        rutledgegroup.mit.edu
                      </a>
                    </strong>
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <i class="fas fa-info-circle"></i> &nbsp; Info
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <strong>Regarding the 48 Laws of Power</strong>, organzational
                psychologists have identified there are{" "}
                <a
                  href="https://www.youtube.com/watch?v=YyXRYgjQXX0"
                  target="_blank"
                >
                  <strong>"takers", "givers", and "matchers"</strong>
                </a>{" "}
                in any given organization, and (being the natural givers that we
                are) being aware of this can be very liberating. The author of
                the "48 Laws of Power"
                <a
                  href="https://www.youtube.com/watch?v=gLt_yDvdeLQ"
                  target="_blank"
                >
                  <strong> has a Ted Talk</strong>
                </a>{" "}
                on how his book came to be (WARNING: he is a slow talker) and
                last but not least:{" "}
                <a
                  href="https://cdn.tke.org/tkeorg/files/file/The_48_Laws_of_Power.pdf"
                  target="_blank"
                >
                  <strong>here</strong>
                </a>{" "}
                is a list of the terrible 48 terrible "laws". I hate them, but
                explain a lot about how unscrupulous people get ahead all the
                time.
                <br />
                <hr />
                <strong>Regarding the Supplements</strong>, it took me a bit to
                make an inventory of them (there are{" "}
                <a
                  href="https://www.alfredorafael.com/wp-content/uploads/2021/05/88766691-3ABF-4CD8-80A7-C9ED6A3DEFF6_1_105_c.jpeg"
                  target="_blank"
                >
                  <strong> more than I thought</strong>
                </a>{" "}
                !) but here they are:
                <br />
                <br />
                <Row className="cards">
                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Spirulina</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          Spirulina is a nutrient-dense algae packed with A, C,
                          E and B vitamins, as well as a whole host of minerals
                          such as calcium, magnesium, zinc and selenium. Its
                          immune system benefits and anti-inflammatory
                          properties made it{" "}
                          <a
                            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3136577/"
                            target="_blank"
                          >
                            {" "}
                            a supplement used by NASA astronauts
                          </a>
                          !
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Alpha Brain</Card.Header>
                      <Card.Body>
                        This supplement is a blend of several cognition-boosting
                        ingredients that work together in a safe and
                        complimentary way to enhance mental performance and
                        recovery. Its a bit pricey but so far, its my favorites
                        (if not my favorite). <br />
                        <a
                          href="https://www.amazon.com/ONNIT-Alpha-Brain-90ct-Concentration/dp/B07WP7Q5BF"
                          target="_blank"
                        >
                          Here on Amazon
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Ashwagandha</Card.Header>
                      <Card.Body>
                        I am not sure of Ashwagandha effects on me (I dont feel
                        much difference as with the previous 2 supplements) but
                        my father SWEARS by it and recommended it to me. I been
                        using it ever since because{" "}
                        <a
                          href="https://www.healthline.com/nutrition/12-proven-ashwagandha-benefits"
                          target="_blank"
                        >
                          {" "}
                          its benefits
                        </a>
                        ... and
                        <a
                          href="https://www.amazon.com/Ashwagandha-Extract-Withanolides-Concentration-Absorption/dp/B08DP4DV76/ref=sr_1_1_sspa?dchild=1&keywords=Withanolides%3A+Ashwa-70&qid=1620943544&s=hpc&sr=1-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExRFczRUhMR003NEdVJmVuY3J5cHRlZElkPUEwMTYyNDY0NFNBWDROV01UWVdXJmVuY3J5cHRlZEFkSWQ9QTAxODc5MjVSSjk5MEtBVUowWFcmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl"
                          target="_blank"
                        >
                          {" "}
                          here is on Amazon
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row className="cards">
                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Turmeric</Card.Header>
                      <Card.Body>
                        An anti-inflammatory that reduces our risk of heart
                        disease, Turmeric (close to Ginger) is a killer natural
                        supplement to have!{" "}
                        <a
                          href="https://www.healthline.com/nutrition/top-10-evidence-based-health-benefits-of-turmeric"
                          target="_blank"
                        >
                          {" "}
                          Here for its benefits
                        </a>
                        ... and
                        <a
                          href="https://www.amazon.com/Gaia-Herbs-Turmeric-Strength-Capsules/dp/B00F1J8K4I/ref=sr_1_4?dchild=1&keywords=turmeric+curcumin+gaia&qid=1620944507&rdc=1&s=hpc&sr=1-4"
                          target="_blank"
                        >
                          {" "}
                          here is the best I found (and use) on Amazon
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Magnesium</Card.Header>
                      <Card.Body>
                        Magnesium{" "}
                        <a
                          href="https://www.healthline.com/health/magnesium-for-migraines#types-of-magnesium"
                          target="_blank"
                        >
                          help us fight migraines
                        </a>{" "}
                        and I started using it because a friend treated her
                        terrible headaches with this supplement (doctor's
                        prescribed!). She recommended me{" "}
                        <a
                          href="https://www.healthline.com/health/magnesium-for-migraines#types-of-magnesium"
                          target="_blank"
                        >
                          this one in particular
                        </a>{" "}
                        so that's the one I been using ever since.
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Omega 3</Card.Header>
                      <Card.Body>
                        We all know the{" "}
                        <a
                          href="https://www.webmd.com/hypertension-high-blood-pressure/guide/omega-3-fish-oil-supplements-for-high-blood-pressure"
                          target="_blank"
                        >
                          beneftis of Omega 3 Oils
                        </a>{" "}
                        but not all supplements are made equal.{" "}
                        <a
                          href="hhttps://www.amazon.com/gp/product/B002CQU564/ref=ox_sc_act_title_1?smid=AD4JTYB7A7HOQ&psc=1"
                          target="_blank"
                        >
                          This particular brand of it
                        </a>
                        is the one I use because of its EPA & DHA.
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <br />
                <Row className="cards">
                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Vitamin D3</Card.Header>
                      <Card.Body>
                        We produce Vitamin D3 naturally, when exposed to
                        sunlight but in New England's long-dark winters this is
                        not always a possibility.{" "}
                        <a
                          href="https://www.webmd.com/drugs/2/drug-10175/vitamin-d3-oral/details"
                          target="_blank"
                        >
                          The benefits of Vitamin D3
                        </a>{" "}
                        include stronger bones and healtier skin, and I learn
                        about it pretty recently, during the quarantine.{" "}
                        <a
                          href="https://www.amazon.com/Vitamin-Enhanced-Coconut-Absorption-Softgels/dp/B00JGCBGZQ/ref=sr_1_10?crid=3SS2V0N3X3H2S&dchild=1&keywords=d3+vitamin+5000+iu&qid=1620945526&s=hpc&sprefix=D3%2Chpc%2C170&sr=1-10"
                          target="_blank"
                        >
                          <br />
                          This is the brand that I use
                        </a>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col>
                    <Card className="highlight-on-hover">
                      <Card.Header>Irish Moss</Card.Header>
                      <Card.Body>
                        I learned about this one thru one of my roomates.
                        Carotenoids found in Irish moss act as antioxidants,
                        which are known to help fight free radicals that can
                        damage cells, causing them to grow in cancerous ways.{" "}
                        <a
                          href="https://www.webmd.com/drugs/2/drug-10175/vitamin-d3-oral/details"
                          target="_blank"
                        >
                          Here is more about the benefits
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.amazon.com/Bladderwrack-Capsules-Digestive-Anti-Inflammatory-Gluten-Free/dp/B0815QWCW1/ref=sr_1_4_sspa?dchild=1&keywords=irish+moss&qid=1620945739&sr=8-4-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUE0NVpIQkJCVVIzVDkmZW5jcnlwdGVkSWQ9QTAzMDc0NTNGUkpMUThNUEJUN0gmZW5jcnlwdGVkQWRJZD1BMTAzODkwMjFPSTRaRVY5MjA1UlAmd2lkZ2V0TmFtZT1zcF9hdGYmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl"
                          target="_blank"
                        >
                          this
                        </a>{" "}
                        is the one I use
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <br />
                <hr />
                <br />
                <strong>Regarding the salsa courses</strong>, I can't stress
                enough how POSTIVE and full of joy they are. You would
                ab-solutely love'em!
                <br />
                <br />
                <a
                  href="https://www.salsaycontrol.com/classes/cambridge"
                  target="_blank"
                >
                  <strong> Salsa y Control</strong>
                </a>{" "}
                is the school that I started at, and then I transitioned to{" "}
                <a
                  href="https://www.rumbaytimbal.com/schedule-2/"
                  target="_blank"
                >
                  <strong> Rumba y Timbal </strong>
                </a>{" "}
                which is the one I would recommend because I am certain they are
                having classes for vaccinated dance students.
                <br />
                <br />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <i class="fas fa-user-friends"></i>&nbsp; Other
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div>
                  <h3>Hello Rebecca</h3>
                  <hr />
                  As we talked before, I met Kathryn years ago! She had a
                  different name, another face and we were working on a
                  different project, but nonetheless "Kathryn": clients who
                  extend the duration of a project with a never-ending array of
                  theme customizations that respond, not to accessibility or
                  practical UX considerations, but to an ever-fluctuating
                  subjective set of tastes: "How about if you could [{" "}
                  <i>insert brand/stlying customization request here </i>] ?".
                  As far as I am concerned, this is not a good use of your time
                  and expertise because every second you spend on such
                  impractical branding/style, is time you could invest on many
                  of your other responsibilities, connecting/exploring lunch
                  locations with your super-cool Puertorican coworkers 😏, etc.
                  <br />
                  <br />
                  The question is how do we express such a dissagreeable idea in
                  Rebecca's elegant, caring fahsion. What do you think about the
                  following?
                  <br />
                  <br />
                  <div style={{ padding: "3%" }}>
                    Dear Kathrene,
                    <br />
                    <br />
                    Thanks for your update request on [<i>insert date here</i>]
                    regarding the [<i>insert styling request here</i>]. It is
                    noteworthy, however, that the scope of our project is
                    concerned with the creation and deployment of the E4E
                    website, updating its content, and making sure all pages
                    comply with our NDA requirements and other web-accessiblity
                    guidelines.{" "}
                    <span style={{ color: "grey" }}>
                      {" "}
                      These frequent, branding-like styles requests of yours are
                      getting a little waaaay out of hand, I have plenty of
                      documentation/evidence of this (yes: your long-ass emails)
                      and TBH, I dont have the time or patience for this cr...
                      [oh! sorry: I got carried away. I'll continue in Rebecca's
                      caring, classy-style now]
                    </span>
                    . I look forward to being as accommodating as possible with
                    your styling needs, while taking into consideration how
                    these frequent design requests may extend beyond the healthy
                    scope of our project. Although I understand that
                    articlulating desing ideas may be difficult (and how often
                    these look different when observed in our computer screens),
                    it is important to consider at what point these frequent
                    styling changes come to a healthy end and a higher focus on
                    websiste's stability and content updates takes its place.
                    <br />
                    <br />
                    Thank you for your understanding. Should you have additional
                    questions...{" "}
                    <span style={{ color: "grey" }}>
                      Closes in Rebecca's fashion
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <div style={{ height: "55vh" }}></div>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Hailu;
