import React from "react";
import { Container } from "react-bootstrap";
import TheFooter from "../../elements/TheFooter";
import TheNavbar from "../../elements/TheNavbar";
import TheLink from "../../elements/TheLink";
import OuterContainer from "../../elements/OuterContainer";
import TheQuickEmail from "../../elements/TheQuickEmail";
import "./edglo.css";

// I dont want to make larger-than-life promises not because I cant... but because I dont want to tell you. I rather show you.

const Edglo = () => {
  return (
    <OuterContainer>
      <TheNavbar
        bgcolor="#040182"
        text={"Post-EdGlo Journey"}
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        replyButton={true}
        personalItems={true}
      />
      <Container id="edglo">
        <br />
        <h4>Dear EdGlo,</h4>
        <br />
        Writing about our personal and professional journeys' after our student
        experience is such a great idea! Thank you for creating this group, and
        Max for nominating me 😃 .
        <br />
        <br />
        For me, EdGlo was a life-changing experience for many reasons: it was my
        first time truly living abroad, and I develop a deep love for research
        on this process.
        <br />
        <br />
        <section className="pic-and-text" style={{ display: "flex" }}>
          <div
            className="pic-and-text-text"
            style={{ flex: "1.5", paddingRight: "1%" }}
          >
            After graduating EdGlo I traveled to Boston, Massachusetts, where I
            taught for 5 years. During this time I learned about curriculum
            development and classroom management in a way I never thought
            possible. All the knowledge in research, educational policies and
            their global trends I acquired in EdGlo made me into a great
            academic, but the day-to-day classroom experience was a completely
            different experience: I had so much learning to do! It certainly was
            a very hard first year, but I triumphantly got thru it.
            <br />
            <br />I taught all levels (from elementary thru high school) and
            experienced the rewarding feeling that characterizes the teaching
            profession. Soon after I realized how education in America has been
            highjacked by a toxic ideology that instructs us to judge students
            based on race, as opposed to their individual character or
            abilities. Although this ideology,{" "}
            <TheLink
              url="https://www.blackburn.senate.gov/2021/7/why-is-critical"
              text="critical race theroy"
            />
            (CRT), claims to fight racism, it creates resentment and division
            among both students and faculty alike by forcing an antagonistic
            "oppressor vs oppressed" narrative rooted in Marxism.
          </div>

          <div className="sidePictureDiv" style={{ backgroundColor: "white" }}>
            <img
              src="http://www.alfredorafael.com/wp-content/uploads/2024/03/Teaching-me-2.jpeg"
              className="sidePicture"
            />
            <div>
              Teaching elementary is one of the most rewarding experiences I had
            </div>
          </div>
        </section>
        <br />
        <span>
          {" "}
          The good news is that{" "}
          <a href="https://www.youtube.com/watch?v=7RICtJFk5a4" target="_blank">
            <strong> parents are actively pushing against </strong>
          </a>
          this dangerous ideology, and some states are creating legislation
          against it. After a couple of harsh encounters with CRT, I decided to
          walk away from the field of education because of the divisive and
          demoralizing impact it was having on my students. Poor remuneration
          and students' behavioral issues also played a factor.
        </span>
        <br />
        <br />
        <span style={{ whiteSpace: "none" }}>
          When deciding on my new career, I decided to go for web development,
          learned how to code, and I am working as a software engineer. At
          first, I thought that entering a technical field so far removed from
          the field of education was going to put me at a dissadvantage.
          However, I noticed that many software engineers lack the very same
          skills that come so naturally to me as an educator, and how
          communicating ideas and organizing them effectively is actually an
          advantage that elevates me among other engineers. Recently, I
          <a href="https://www.youtube.com/watch?v=jqRIbN3WlpM" target="_blank">
            <strong> created a video </strong>
          </a>
          explaining some software development concepts for technical
          recruiters, they enjoy it a lot, and I realized that teaching is
          something I still enjoy immensely. I am definitively not discarding
          using my coding skills to teach software development in the future.
          You may find my{" "}
          <a href="https://www.alfredodeveloper.com/resume" target="_blank">
            <strong>current resume here</strong>.
          </a>
        </span>
        <br />
        <br />
        <span style={{ whiteSpace: "one" }}>
          Feel free to reach out to me with questions, if you have any! If I
          were to pass the baton to hear about the post-EdGlo journey from
          another student, I would like to nominate{" "}
          <a
            href="https://www.linkedin.com/in/esko-nummenmaa-773a5285/?originalSubdomain=iq"
            target="_blank"
          >
            <strong> Esko Nummenmaa </strong>
          </a>
          , if he is so inclined 😊
          <br />
          <br />
          All the best
          <br />
          <strong>Alfredo R. Pabon, M.Ed</strong>
        </span>
        <TheQuickEmail
          placeholder="Reply on Linkedin's wall, or here for a personal message :)"
          buttonText="Well, send it!"
          from="Edglo Page"
        />
      </Container>
      <TheFooter
        textLeft="Written with ❤️ by Alfredo R. Pabon"
        imgSrc="http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        // imgSrc="https://i.redd.it/snoovatar/avatars/98946c81-6a9d-41ee-86ed-f92203e800e3.png"
        currentYear
      />
    </OuterContainer>
  );
};

export default Edglo;
