import React, { useEffect, useState } from "react";
import TheFooter from "../../elements/TheFooter";
import TheNavbar from "../../elements/TheNavbar";
import TheLink from "../../elements/TheLink";
import OuterContainer from "../../elements/OuterContainer";
import TheQuickEmail from "../../elements/TheQuickEmail";
import TheCard from "../../elements/TheCard";
import TheModal from "../../elements/TheModal";
import ThePopComment from "../../elements/ThePopComment";
import ThePictureOnSide from "../../elements/BigPictureOnSide";
import AR_Icon from "../../elements/AR_Icon";
import Fade from "react-reveal/Fade";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import "./generic.css";
import EmailJS1 from "../EmailJS1";
import ThumbnailOnSide from "../../elements/ThumbnailOnSide";
//import jsonDataFile from "./data.json";

const jsonData = require("./data");

const B2Generic = () => {
  //////////state for data.json file//////////
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <Card.Img variant="top" src={item.pictureUrl} />

            <CardHeader>
              <h5 style={{ textAlign: "left" }}>Backstory:</h5>
            </CardHeader>

            <CardBody>
              <CardText style={{ textAlign: "left" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //////////----------------/////////////---------------- /////////////

  return (
    <OuterContainer>
      <TheNavbar
        bgcolor="#040182"
        text={"All componets, one page"}
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        replyButton={true}
        personalItems={true}
      />
      <Container id="generic">
        <br />
        <h4>Title Goes Here</h4>
        <br />
        Dear Generic,
        <br />
        <br />
        <ThePictureOnSide
          imgSrc="http://placedog.net/600/500"
          alt="kitten"
          imgText="Some text describing the pic"
          picSide="right"
        >
          Lorem ipsum dolor sit amet,{" "}
          <TheLink text="link consectetur adipiscing" url="www.google.com" />
          consectetur adipiscing elit. Aliquam iaculis et justo at bibendum.
          Class aptent taciti sociosqu ad litora torquent per conubia nostra,
          per inceptos himenaeos. Aliquam et dui ipsum. Red neg. Integer
          tristique, quam in faucibus convallis, nulla leo iaculis magna, sit
          amet mollis nulla eros in orci. Fusce dapibus eget orci rutrum
          vulputate. Etiam eu dolor quis elit egestas gravida. Suspendisse
          faucibus lorem enim, non elementum mauris dapibus ut. Red.
          <br />
          <br />
          Suspendisse ut ipsum ut augue condimentum finibus eu in mauris. Proin
          tortor purus, aliquam a magna at, accumsan condimentum eros. Proin
          pulvinar purus eget nibh egestas, vel vestibulum nisi vehicula.
          <br />
          <br />
          Aenean vel convallis ex. Duis blandit diam ipsum, condimentum cursus
          justo ullamcorper id. Pellentesque scelerisque dictum lorem, id
          sollicitudin nibh vulputate quis. Praesent quis gravida velit.
          <TheModal triggerElement="Modal 2" headerText="Modal 2">
            <ThumbnailOnSide
              imgSrc={"http://placedog.net/200/200"}
              alt={"alt text"}
              right
              imgText={"Some text describing the pic"}
            >
              Starts here ipsum dolor sit amet consectetur adipisicing elit.
              Fuga deleniti quos excepturi quo consectetur totam consequuntur,
              odio voluptatem vel aperiam atque ducimus sit maxime ea. Mollitia
              cum illum asperiores molestias. Lorem ipsum dolor sit amet
              consectetur adipisicing elit.
            </ThumbnailOnSide>
          </TheModal>
          , Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor
          ratione ducimus voluptas, maxime qui repellendus saepe, tempore enim
          voluptatibus totam incidunt similique inventore nemo id tenetur
          provident perferendis vel sit. Lorem ipsum dolor, sit amet consectetur
          adipisicing elit. Ratione tempora velit hic unde ex libero, atque in
          placeat dolorum perspiciatis quidem nulla labore provident qui
          voluptatem. Nobis, odit. Asperiores, repellendus?
        </ThePictureOnSide>
        <br />
        <ThumbnailOnSide
          imgSrc={"http://placedog.net/200/200"}
          alt={"alt text"}
          left
          imgText={"Some text describing the pic"}
        >
          Starts here ipsum dolor sit amet consectetur adipisicing elit. Fuga
          deleniti quos excepturi quo consectetur totam consequuntur, odio
          voluptatem vel aperiam atque ducimus sit maxime ea. Mollitia cum illum
          asperiores molestias. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Fuga deleniti quos excepturi quo consectetur totam
          consequuntur, odio voluptatem vel aperiam atque ducimus sit maxime ea.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Fuga deleniti quos excepturi quo
          consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor
          sit amet consectetur adipisicing elit. Fuga deleniti quos excepturi
          quo consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Fuga deleniti quos excepturi quo consectetur totam consequuntur, odio
          voluptatem vel aperiam atque ducimus sit maxime ea. Mollitia cum illum
          asperiores molestias. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Mollitia cum illum asperiores molestias. Lorem ipsum
          dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Ends here
        </ThumbnailOnSide>
        <br />
        {/* EMAIL JS //////////////////////////////////////////////////////////////////// */}
        <TheQuickEmail
          buttonText="Send it"
          placeholder="Your message goes here"
          from="Generic page"
        />
        <br />
        <br />
        {/* CARDS  //////////////////////////////////////////////////////////////////// */}
        <h4>Cards</h4>
        <br />
        <Row className="cards">
          <Col>
            <TheCard headerText="Card 1">Card 1</TheCard>
          </Col>

          <Col>
            <TheCard headerText="Card 2">Card 2</TheCard>
          </Col>

          <Col>
            <TheCard headerText="Card 3">Card 3</TheCard>
          </Col>
        </Row>
        <br />
        <br />
        {/* CARDS + MODALS //////////////////////////////////////////////////////////////////// */}
        <h4>Cards with modals</h4>
        <br />
        <Row className="modal-cards">
          <Col>
            <TheModal
              triggerElement={
                <TheCard
                  headerText="Card 1"
                  className="highlight-on-hover"
                  style={{ color: "black" }}
                >
                  Card 1
                </TheCard>
              }
              headerText="Another Modal"
            >
              {/* Content inside the modal */}
              <p>This is another modal 1.</p>
            </TheModal>
          </Col>

          <Col>
            <TheModal
              triggerElement={
                <TheCard headerText="Card 2" className="highlight-on-hover">
                  Card 2
                </TheCard>
              }
              headerText="Another Modal 2"
            >
              {/* Content inside the modal */}
              <p>This is another modal 2</p>
            </TheModal>
          </Col>

          <Col>
            <TheModal
              triggerElement={
                <TheCard headerText="Card 3" className="highlight-on-hover">
                  Card 1
                </TheCard>
              }
              headerText="Another Modal 3"
            >
              {/* Content inside the modal */}
              <p>This is another modal 3</p>
            </TheModal>
          </Col>
        </Row>
        <br />
        <br />
        {/* Juxtaposed text ////////////////////////////////////////////////////////// */}
        <h3>Juxtaposed response</h3>
        <br />
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>Original</h4>
            </div>
            <i>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
              doloribus culpa qui vitae magni veniam nam possimus veritatis
              molestias amet est eaque numquam rerum ducimus nulla, eum in vero
              molestiae?
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>Response</h4>
            </div>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
            doloribus culpa qui vitae magni veniam nam possimus veritatis
            molestias amet est eaque numquam rerum ducimus nulla, eum in vero
            molestiae?
          </Col>
        </Row>
        <hr />
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ fontSize: "90%" }}>
            <i>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
              doloribus culpa qui vitae magni veniam nam possimus veritatis
              molestias amet est eaque numquam rerum ducimus nulla, eum in vero
              molestiae?
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
            doloribus culpa qui vitae magni veniam nam possimus veritatis
            molestias amet est eaque numquam rerum ducimus nulla, eum in vero
            molestiae?
          </Col>
        </Row>
        <hr />
        <br />
        {/* FROM JSON DATA //////////////////////////////////////////////////////////////////// */}
        <h4>Dinamycally Generated Items</h4>
        <Row>{renderItems}</Row>
        {/* //////// COMMENTED TEXT ///////////////////////////////////////////// */}
        <h3>Pop commented text</h3>
        <ThePopComment
          showContent={
            <div className="">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              iaculis et justo at bibendum. Class aptent taciti sociosqu ad
              litora torquent per conubia nostra, per inceptos himenaeos.
              Aliquam et dui ipsum. Integer tristique, quam in faucibus
              convallis, nulla leo iaculis magna, sit amet mollis nulla eros in
              orci. Fusce dapibus eget orci rutrum vulputate. Etiam eu dolor
              quis elit egestas gravida. Suspendisse faucibus lorem enim, non
              elementum mauris dapibus ut.
            </div>
          }
          popContent={
            <strong>
              Response 1
              <br />
              <br />
              Suspendisse ut ipsum ut augue condimentum finibus eu in mauris.
              Proin tortor purus, aliquam a magna at, accumsan condimentum eros.
              Proin pulvinar purus eget nibh egestas, vel vestibulum nisi
              vehicula. Aenean vel convallis ex. Duis blandit diam ipsum,
              condimentum cursus justo ullamcorper id. Pellentesque scelerisque
              dictum lorem, id sollicitudin nibh vulputate quis. Praesent quis
              gravida velit.
            </strong>
          }
        />
        <ThePopComment
          showContent={
            <span className="">
              Secondio lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Aliquam iaculis et justo at bibendum. Class aptent taciti sociosqu
              ad litora torquent per conubia nostra, per inceptos himenaeos.
              Aliquam et dui ipsum. Integer tristique, quam in faucibus
              convallis, nulla leo iaculis magna, sit amet mollis nulla eros in
              orci. Fusce dapibus eget orci rutrum vulputate. Etiam eu dolor
              quis elit egestas gravida. Suspendisse faucibus lorem enim, non
              elementum mauris dapibus ut.
            </span>
          }
          popContent={
            <strong>
              Response 2
              <br />
              <br />
              Suspendisse ut ipsum ut augue condimentum finibus eu in mauris.
              Proin tortor purus, aliquam a magna at, accumsan condimentum eros.
              Proin pulvinar purus eget nibh egestas, vel vestibulum nisi
              vehicula. Aenean vel convallis ex. Duis blandit diam ipsum,
              condimentum cursus justo ullamcorper id. Pellentesque scelerisque
              dictum lorem, id sollicitudin nibh vulputate quis. Praesent quis
              gravida velit.
            </strong>
          }
        />
        <br />
        <br />
        {/* ACCORDIONS //////////////////////////////////////////////////////////////////// */}
        <h4>Accordeon</h4>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Click me
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>Hello! I'm the body</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Click me!
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>Hello! I'm another body</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        {/* FULL DATA MAIL FORM //////////////////////////////////////////////////////////// */}
        <h4>Let's connect!</h4>
        <br />
        <EmailJS1 />
      </Container>
      <TheFooter
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        textLeft={"Some text goes here"}
        year={true}
      />
    </OuterContainer>
  );
};

export default B2Generic;
