import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TheNavbar from "../../elements/TheNavbar";
import TheQuickEmail from "../../elements/TheQuickEmail";
import TheFooter from "../../elements/TheFooter";
import "./motola.css";
import OuterContainer from "../../elements/OuterContainer";

const AbigailOmotola = () => {
  return (
    <OuterContainer>
      <TheNavbar
        bgcolor="#040182"
        text={"My Messaging"}
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        replyButton={true}
        personalItems={true}
      />{" "}
      <Container id="Motola1">
        <br />
        <h4>Querida Motola</h4>
        <br />
        <Row className="row-text-with-pic">
          <Col className="row-text">
            Qué experiencia tan hermosa, emocionante y en ocasiones intimidante
            ha sido conocerte, explorar nuestras historias, valores, sueños para
            el futuro y nuestros deseos de continuar compartiendo nuestro ser,
            bajo el Amparo y Bendición de Dios, para forjar y nutrir una
            relación en la cual lleguemos nos convirtamos en lo que la Biblia
            llama "
            <a
              href="https://www.biblegateway.com/passage/?search=Marcos%2010%3A8&version=RVR1960"
              target="_blank"
            >
              <strong>una sola carne</strong>
            </a>
            ".
            <br />
            <br />
            No tengo palabras para describirte cuánto me encanta la
            transparencia e intencionalidad de nuestras conversaciones, tu
            afable personalidad, la bondad y compasión que fluyen TAN
            naturalmente de tu corazón, la elegancia de tus pasos (tanto los
            físicos como los espirituales), el carácter moral con el que te
            encomiendas (a tí y nuestra relación) a Dios y honestamente, si el
            matrimonio es Su voluntad para mi vida, aspiro con temor (pero sin
            reservas) a explorar esa dirección contigo, en nuestro noviazgo.
            <br />
            <br />
            Cada conversación difícl... cada momento agradable que compartimos,
            cada una de nuestras conversaciones profundas ("nerdy" 😋), y en
            cada fugaz momento de pasión, me siento cada vez más sumamente
            convencido de que camino en la dirección correcta. Por ende, le pido
            a Dios que me bendiga con Su sabiduría, para saber cómo dirigir mis
            pasos y no errar... fortaleza, para caminar con firmeza en la
            dirección que Él me ha iluminado, y de Su Bendición para que nuestra
            relación sea capaz de cumplir Su propósito, tanto en nuestras vidas
            como en la de aquellos que nos rodean.
            <br />
            <br />
          </Col>
          <Col className="row-pic">
            <img
              src="http://www.alfredorafael.com/wp-content/uploads/2024/03/VDay-scaled.jpeg"
              alt=""
            />
            <p className="pic-text">First Vday picture, 2022</p>
          </Col>
        </Row>
        Este fin de semana promete ser grande! Si bien conocer a tus padres y tu
        comunidad espritual es un paso intimidante, lo doy con gran certeza,
        mucha emoción, pero sobre todo (además del análisis intelectual que
        caracteriza mis decisiones), con fe...
        <br />
        <br />
        Last but not least, gracias por abrir la puerta no solo a un renacer
        espiritual (no he visitado la iglesia en años) sino también a un romance
        sin tiempo basado en valores Judeo-Cristianos con una mujer tan hermosa,
        mona y maravillosa como lo eres tú.
        <br />
        <br />
        Con amor,
        <br />
        <strong>Alfredo Rafael</strong>
        <br />
        <br />
        <TheQuickEmail
          from="Motola's frist letter"
          placeholder="Write your reply here... o a mi email, si quieres"
          buttonText="Mándamelo"
        />
        <br />
        <br />
      </Container>
      <TheFooter />
    </OuterContainer>
  );
};

export default AbigailOmotola;
