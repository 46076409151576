import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  OverlayTrigger,
  Popover,
  Accordion,
} from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./moving.css";
import EmailJS1 from "../EmailJS1";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

import jsonDataFile from "./data.json";

const jsonData = jsonDataFile[0].firstArrayOfJsonDataFile;

const MovingPlans = () => {
  ////////////modals for cards////////////
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //////////state for data.json file//////////
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <Card.Img variant="top" src={item.pictureUrl} />

            <CardHeader>
              <h5 style={{ textAlign: "left" }}>Backstory:</h5>
            </CardHeader>

            <CardBody>
              <CardText style={{ textAlign: "left" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  //////////Explanations for Paragraphs /////////////

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="moving">
        <br />
        <h4>Hello,</h4>
        <br />
        Moving is always a complex process so I created this page as a
        simplified "to do list" with sections.
        <br />
        <br />
        Please keep me posted about whatever you think that needs to be added
        here and... Stay tuned!
        <br />
        <br />
        Love,
        <br />
        - Alfredo Rafael
        <br />
        <br />
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-house-user"></i> &nbsp;
              Apartment search
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="">
                  <p className="">
                    <h4>Apartment's search</h4>
                    <hr style={{ borderColor: "grey" }} />
                    <br />
                    <Row className="modal-cards">
                      <Col>
                        <Card
                          onClick={handleShow1}
                          className="highlight-on-hover"
                        >
                          <Card.Header>Brookstone & Terrace</Card.Header>
                          <Card.Body>
                            {/*<Card.Title>Special title treatment</Card.Title> */}
                            <Card.Text>
                              <strong>Terrace B</strong>
                              <br />
                              <ul>
                                <li>2 beds, 1 bath, 800 sq ft</li>
                                <li>Pool/Gym/Laundry (on site) </li>
                                <li>$1,166 – $1,196</li>
                                <li>
                                  Contact: <br />
                                  (972) 607-3948
                                </li>
                              </ul>
                              <strong>Apartments.com</strong>{" "}
                              <a
                                href="https://www.apartments.com/brookstone-terrace-irving-tx/l8h9rgg/"
                                target="_blank"
                              >
                                <strong>link here</strong>
                              </a>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Modal show={show1} onHide={handleClose1} size="lg">
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Screenshot of property details
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <img
                              src="https://www.alfredorafael.com/wp-content/uploads/2021/07/Screen-Shot-2021-07-06-at-11.44.29-PM.png"
                              style={{ maxWidth: "100%" }}
                            />
                          </Modal.Body>
                        </Modal>
                      </Col>

                      <Col>
                        <Card
                          onClick={handleShow2}
                          className="highlight-on-hover"
                        >
                          <Card.Header>Calloway at Las Colinas</Card.Header>
                          <Card.Body>
                            {/*<Card.Title>Special title treatment</Card.Title> */}
                            <Card.Text>
                              <strong>Juniper 2x1</strong>
                              <br />
                              <ul>
                                <li>2 beds, 1 bath, 864 sq ft</li>
                                <li>Pool/Gym/Laundry/Tennis</li>
                                <li>
                                  $1,225 – $1,720 <strong>Furnished</strong>{" "}
                                </li>
                                <li>Contact: (844) 355-4218</li>
                                <li>Controlled Access</li>
                              </ul>
                              <strong>Apartments.com</strong>{" "}
                              <a
                                href="https://www.apartments.com/calloway-at-las-colinas-irving-tx/rsrsxc1/"
                                target="_blank"
                              >
                                <strong>link here</strong>
                              </a>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Modal show={show2} onHide={handleClose2} size="lg">
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Screenshot of property details
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <img
                              src="https://www.alfredorafael.com/wp-content/uploads/2021/07/Screen-Shot-2021-07-07-at-12.22.09-AM.png"
                              style={{ maxWidth: "100%" }}
                            />
                          </Modal.Body>
                        </Modal>
                      </Col>

                      <Col>
                        <Card
                          onClick={handleShow3}
                          className="highlight-on-hover"
                        >
                          <Card.Header>Colinas Pointe</Card.Header>
                          <Card.Body>
                            {/*<Card.Title>Special title treatment</Card.Title> */}
                            <Card.Text>
                              <strong>Unit 0814</strong>
                              <br />
                              <ul>
                                <li>2 beds, 1 bath, 880 sq ft</li>
                                <li>Pool/24hr Gym/Laundry (on site)</li>
                                <li>$1,195</li>
                                <li>Contact: (972) 848-5975</li>
                                <li>Controlled Access</li>
                              </ul>
                              <strong>Apartmentlist.com</strong>{" "}
                              <a
                                href="https://www.apartmentlist.com/shortlist/p2827"
                                target="_blank"
                              >
                                <strong>link here</strong>
                              </a>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Modal show={show3} onHide={handleClose3} size="lg">
                          <Modal.Header closeButton>
                            <Modal.Title>Screenshot</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <img
                              src="https://www.alfredorafael.com/wp-content/uploads/2021/07/Screen-Shot-2021-07-07-at-1.11.21-AM.png"
                              style={{ maxWidth: "100%" }}
                            />
                          </Modal.Body>
                        </Modal>
                      </Col>
                    </Row>
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              &nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-truck"></i> &nbsp; Stuff
              Moving Logistics
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="">
                  <p className="">
                    <h4>Stuff Moving Logistics</h4>
                    Test
                    <br />
                    <br />
                    <img
                      src="https://www.alfredorafael.com/wp-content/uploads/2021/07/Screen-Shot-2021-07-07-at-1.21.12-AM-e1625635448279.png"
                      style={{ maxWidth: "100%" }}
                    />
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-car"></i> &nbsp;
              Car Moving Logistics
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="">
                  <p className="">
                    <h4>Car Moving Logistics</h4>
                    <br />
                    Here I look forward to sharing with you the information of
                    the car-moving vendors I found, their prices and what their
                    individual services entail. Open to questions, suggestions,
                    and... stay tuned!
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<i class="fas fa-plane"></i> &nbsp;
              Traveling Logistics
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="">
                  <p className="">
                    <h4>Traveling Logistics</h4>
                    <br />
                    Here goes information on Plane tickets, dates, etc.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              &nbsp;&nbsp;&nbsp;&nbsp; <i class="fas fa-users"></i> &nbsp;
              Interpersonal Relations
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <div className="">
                  <p className="">
                    <h4>Interpersonal relations</h4>
                    <br />
                    How to handle this with Julita so she does not feel snubbed?
                    This would be more of a conversation than anything else, but
                    you mentioned in the list so I think is worth adding.
                  </p>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Reply this message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Moving Plans"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="You can text me, or send message here (it goes to my email) :)"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default MovingPlans;
