import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import { CardHeader, CardBody, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./imovie.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const IMovie = () => {
  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="dean">
        <br />
        {/* FROM JSON DATA //////////////////////////////////////////////////////////////////// */}
        <h4>About this Demo</h4>
        <br />
        <strong>Rationale</strong>: Either for tutorial and/or demonstrative
        purposes, developers benefit from <u>basic video editing</u> skills.
        <br />
        <br />
        <strong>SWBAT</strong>: At the end of this short tutorial, participants
        will be able to <u>cut-out specific portions</u> of a video with ease.
        <br />
        <br />
        <hr />
        <h4>Resources for Follow-Along #1</h4>
        On{" "}
        <a href="https://www.youtube.com/watch?v=6PZPU5rWWtM" target="_blank">
          <strong>this video</strong>
        </a>
        , we'll <u>cut-out the drinking part</u>, making our video look{" "}
        <a href="https://www.youtube.com/watch?v=8mODduahsZM" target="_blank">
          <strong>like this</strong>
        </a>
        , instead. To start, download{" "}
        <a
          href="https://rmrdetailing.com/wp-content/uploads/2022/07/With-Coffee.mp4"
          target="_blank"
        >
          <strong>this video</strong>
        </a>{" "}
        to your computer files, open the iMovie app, and follow along with me.
        Let the learning begin!
        <br />
        <br />
        <hr></hr>
        <h4>Resources for Follow-Along #2</h4>
        Based on the previous session, use the following files/examples to
        create a voice over like the one you see under "final result".
        <br />
        <br />
        <Container>
          <Row>
            <div style={{ marginRight: "20px" }}>
              <strong>Raw files</strong>:
              <br />
              <ul>
                <li>
                  <a
                    href="https://rmrdetailing.com/wp-content/uploads/2022/07/Demo.mp4"
                    target="_blank"
                  >
                    <strong>Presenter</strong>
                  </a>
                </li>
                <li>
                  <a
                    href="https://rmrdetailing.com/wp-content/uploads/2022/07/Livingroom-and-Dinning-table.mp4"
                    target="_blank"
                  >
                    <strong>Apartment</strong>
                  </a>
                </li>
                <li>
                  <a
                    href="https://rmrdetailing.com/wp-content/uploads/2022/07/my-Kitchen-scaled.jpeg"
                    target="_blank"
                  >
                    <strong>Kitchen</strong>
                  </a>
                </li>
                <li>
                  <a
                    href="https://rmrdetailing.com/wp-content/uploads/2022/07/setup-scaled.jpeg"
                    target="_blank"
                  >
                    <strong>Setup</strong>
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <strong>Final Result</strong>:
              <br />
              <ul>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=panqbnPibfg"
                    target="_blank"
                  >
                    <strong>Edited video</strong>
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.youtube.com/watch?v=OTVE5iPMKLg"
                    target="_blank"
                  >
                    {" "}
                    <strong>CGP Grey</strong>
                  </a>{" "}
                  (iMovie example)
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/watch?v=jqRIbN3WlpM"
                    target="_blank"
                  >
                    <strong>Alfredo Rafael</strong>
                  </a>{" "}
                  (iMovie example)
                </li>
              </ul>
            </div>{" "}
            <br />
          </Row>
        </Container>
        <hr></hr>
        <h4>Useful Shortcuts</h4>
        <br />
        <ul>
          <li>
            Use <u>Command + B </u> to cut video.
          </li>
          <li>
            Use the <u>Spacebar</u> to pause & play video
          </li>
          <li>
            Press <u>J</u> to navigate back
          </li>
          <li>
            Use <u>L</u> to fast-forward
          </li>
          <li>
            Save/use your video as <code>.mp4</code> by clicking{" "}
            <i class="fa fa-apple"></i> <u>File</u> > <u>Share</u> > <u>File</u>{" "}
            > <u>Next</u> > <u>Save</u>
          </li>
        </ul>
        <br />
        <br />
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Questions or comments?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Lunch & Learn tutorial"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="Feel free to send an anonymous message :)"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Swoshhh!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default IMovie;
