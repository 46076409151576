import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import { CardHeader, CardBody, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./propuestaMatrimonial.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const PropuestaMatrimonial = () => {
  //////////for data.json file//////////
  const jsonData = require("./data");

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <Card.Img variant="top" src={item.pictureUrl} />

            <CardHeader style={{ paddingLeft: "1.3rem" }}>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "90%",
                  marginBottom: "0",
                }}
              >
                Backstory:
              </h6>
            </CardHeader>

            <CardBody>
              <CardText style={{ textAlign: "left" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="propuestaMatrimonial">
        <br />
        {/* FROM JSON DATA //////////////////////////////////////////////////////////////////// */}
        <h4>Amada mía</h4>
        Te envío este link para que accedas las fotos y video en un mísmo lugar.
        Más adelante espero agregar las que tomó Julia. Bendiciones!
        <br />
        <br />
        Con amor,
        <br />
        <strong>Alfredo Rafael</strong>
        <br />
        <Row>{renderItems}</Row>
        <br />
        <div style={{ textAlign: "center", padding: "0 10%" }}>
          The following is a 3-minute clip the photographer put together for us
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <iframe
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            src="https://www.youtube.com/embed/zi9v0j7Eib8"
            frameborder="0"
            allow="autoplay"
            allowfullscreen
            title="Motola's Proposal Picture"
            width="560"
            height="315"
          />{" "}
        </div>
        <br />
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Send me a message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="GodGivenTribe"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="You can reply here, or text to my phone :)"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default PropuestaMatrimonial;
