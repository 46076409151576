import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./godGivenTribe.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const GodGivenTribe = () => {
  ////////////modals for cards////////////
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="GodGivenTribe">
        <br />
        <h4>Hello fam,</h4>
        Still to date ya'll don't have each other's contact information and I
        absolutely think ya'll should, beacuse I live all the way in Texas now
        and gotta keep the tribe together, ya'll. Ya'll might be able to tell
        how Texan I am feeling by the amount of <i>ya'll</i> I been using in
        this message. I also bought a cowboy hat! Someday I'll get over it. See
        ya'll soon ❤️
        <br />
        <br />
        Love, <br />
        <strong>Alfredo Rafael</strong>
        {/* 
        Moving to TX means intimate-circle have expanded (both
        in numbers and geographically) keeping in touch becomes a bit harder.
        This is a way of keeping our growing tribe connected 💪🏼!
         */}
        <br />
        <br />
        <Row className="pic-with-data-row">
          <Col>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src="https://rmrdetailing.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-21-at-10.53.51-PM.png"
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                My God given parents, as stated in Psalm 27:10-14: Suzy & Jose{" "}
                <a href="tel:6179590071">(617)959-0071</a> & email{" "}
                <a href="mailto:jvsuez@yahoo.com">jvsuez@yahoo.com</a>
              </figcaption>
            </figure>
          </Col>
          <Col>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src="https://rmrdetailing.com/wp-content/uploads/2022/05/IMG_4305.jpeg"
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                My lovely father & mother in law: Mr. & Ms. Peters&nbsp;
                <a href="tel:2149062965">(214)906-2965</a> & email{" "}
                <a href="mailto:fkpetersexcels@gmail.com">
                  fkpetersexcels@gmail.com
                </a>{" "}
                {/*  <a href="mailto:deindepeters@gmail.com">deindepeters@gmail</a>  */}
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="pic-with-data-row">
          <Col>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src="https://rmrdetailing.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-22-at-12.58.18-AM.png"
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                My God given answer to the Proverbs' 31:10 question:
                Motola&nbsp;
                <a href="tel:4698665044">(469)866-5044</a> & email{" "}
                <a href="mailto:motolapeters@gmail.com">
                  motolapeters@gmail.com
                </a>
              </figcaption>
            </figure>
          </Col>

          <Col>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src="https://rmrdetailing.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-22-at-12.47.02-AM.png"
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                My sister Julia, better known (by me) as "Julita Julita..."{" "}
                <a href="tel:4698700244">(469)870-0244</a> & email{" "}
                <a href="mailto:jvsuez@yahoo.com">julia.dpabon@gmail.com</a>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <Row className="pic-with-data-row">
          <Col>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src="https://rmrdetailing.com/wp-content/uploads/2022/03/IMG_2094.jpeg"
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                My sister Meky, better known by me as "Mekita"...{" "}
                <a href="tel:7876731607">(787)673-1607</a> & email{" "}
                <a href="mailto:jvsuez@yahoo.com">americagaviria@gmail.com</a>
              </figcaption>
            </figure>
          </Col>
          <Col className="desaparece" style={{ visibility: "hidden" }}>
            <figure
              className="figure pic-with-data"
              style={{ display: "flex" }}
            >
              <img
                src=""
                className="figure-img img-fluid rounded pic-with-data-img"
                alt="..."
              />
              <figcaption className="pic-of-data">
                Placeholder &nbsp;
                <a href="tel:4698665044">(555)555-5555</a> & email{" "}
                <a href="mailto:motolapeters@gmail.com">
                  placeholder@gmail.com
                </a>
              </figcaption>
            </figure>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Send me a message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="GodGivenTribe"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="You can reply here, or text to my phone :)"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default GodGivenTribe;
