import React from "react";

import "./home.css";
import HiddenRoute from "../../elements/HiddenRoute";
import Jerry from "../Jerry";
import BadPassword from "../Z_BadPassword";
import Generic from "../B2Generic";
import AmorPropio from "../MorPropio";
import Myles from "../Myles";
import Hailu from "../Hailu";
import C3Library from "../C3Library";
import MovingPlans from "../MovingPlans";
import D4Library from "../D4Library";
import Motola from "../AbigailOmotola";
import GodGivenTribe from "../GodGivenTribe";
import PropuestaMatrimonial from "../PropuestaMatrimonial";
import Fade from "react-reveal/Fade";
import IMovie from "../IMovie";
import NoMaam from "../NoMaam";

const A1_EntryPoints = () => {
  const checkIdentity = function () {
    let userResponse = prompt("Enter password provided for your access:");
    switch (userResponse.toLowerCase()) {
      case "generic":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="generic"
            pw2="Generic"
            component={<Generic />}
          />
        );
      case "lib":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="lib"
            pw2="Lib"
            component={<C3Library />}
          />
        );
      case "moving":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="Moving"
            pw2="moving"
            component={<MovingPlans />}
          />
        );
      case "jerry":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="Jerry"
            pw2="jerry"
            component={<Jerry />}
          />
        );
      case "mylesg":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="mylesg"
            pw2="MylesG"
            component={<Myles />}
          />
        );
      case "amorpropio":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="AmorPropio"
            pw2="amorpropio"
            component={<AmorPropio />}
          />
        );
      case "hailu":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="hailu"
            pw2="Hailu"
            component={<Hailu />}
          />
        );
      case "xy":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="xy"
            pw2="Xy"
            component={<D4Library />}
          />
        );
      case "omotola":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="Omotola"
            pw2="omotola"
            component={<Motola />}
          />
        );
      case "family":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="Family"
            pw2="family"
            component={<GodGivenTribe />}
          />
        );
      case "proposal":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="proposal"
            pw2="Proposal"
            component={<PropuestaMatrimonial />}
          />
        );
      case "NoMaam":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="No maam"
            pw2="no maam"
            component={<NoMaam />}
          />
        );
      case "mtech":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="mtech"
            pw2="Mtech"
            component={<IMovie />}
          />
        );
      // add <NoMaam />
      case "no maam":
        return (
          <HiddenRoute
            userResponse={userResponse}
            pw1="No maam"
            pw2="no maam"
            component={<NoMaam />}
          />
        );

      default:
        return <BadPassword />;
    }
  };

  return (
    <React.Fragment>
      <Fade>
        <div style={{ height: "100vh" }}>{checkIdentity()}</div>
      </Fade>
    </React.Fragment>
  );
};

export default A1_EntryPoints;
