import React, {useState} from 'react';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Row,
  Container
} from 'reactstrap';



const Bar = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);


    return (
      <React.Fragment>

      <Navbar dark style={{background: '#040182', borderBottom: '1px solid #040182'}} expand="md" sticky={'top'}>
      <Container>

        <NavbarBrand href="/">
        &nbsp; <span className="navbar-logo-text">Private Collection</span>
        </NavbarBrand>
        
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
         
           
          
          
           
          </Nav>
          
          &nbsp; <NavbarText><span style={{color: 'white'}}>Very personal</span></NavbarText>
          
        </Collapse>
        </Container>
      </Navbar>
      </React.Fragment>
    );
  }

  export default Bar;