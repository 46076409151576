import React from "react";
import { Card } from "react-bootstrap";

const TheCard = ({ headerText, children }) => {
  return (
    <Card>
      <Card.Header>{headerText}</Card.Header>
      <Card.Body>
        <Card.Text>{children}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default TheCard;
