import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./Jerry.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const Jerry = () => {
  ////////////modals for cards////////////
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="jerry">
        <br />
        <h4>Our markdown formulas</h4>
        <br />
        Hey! I found{" "}
        <a
          href="https://rmrdetailing.com/wp-content/uploads/2022/02/FullSizeRender.jpeg"
          target="_blank"
          rel="noreferrer"
        >
          <strong>our formulas on this paper</strong>
        </a>{" "}
        from when we were practicing markdown exercises. I thought you might
        wanna review these at some point so I am adding them to this webpage.
        Click on the variables if you forgot what we meant them to be. Hope this
        helps and if you{" "}
        <strong>
          <i>ever</i>
        </strong>{" "}
        have a math question, dont hesistate in reaching out man! Hugs from
        Irving
        <br />
        <br />
        {/*------------------------------------------*/}
        <strong>1) Formula for Sale Price </strong>
        <div style={{ whiteSpace: "none", marginLeft: "5%" }}>
          SP ={" "}
          <span className="modal-word" onClick={handleShow1}>
            OP
          </span>{" "}
          x{" "}
          <span className="modal-word" onClick={handleShow2}>
            d
          </span>{" "}
          -{" "}
          <span className="modal-word" onClick={handleShow3}>
            OP
          </span>{" "}
        </div>
        <br />
        <i>
          Meaning: The "original price" multiplied by the "discount", minus
          "original price" equals the "sale price"
        </i>
        <br />
        <br />
        {/*------------------------------------------*/}
        <strong>2) Formula for Original Price </strong>
        <div style={{ whiteSpace: "none", marginLeft: "5%" }}>
          OP ={" "}
          <span className="modal-word" onClick={handleShow4}>
            SP
          </span>{" "}
          / 1 -{" "}
          <span className="modal-word" onClick={handleShow2}>
            d
          </span>{" "}
        </div>
        <br />
        <i>
          Meaning: The "sale price" divided by <strong>1</strong>, minus the
          "discount" equals what the "original price" was
        </i>
        <br />
        <br />
        {/*------------------------------------------*/}
        <strong>4) Formula for applied discount </strong>
        <div style={{ whiteSpace: "none", marginLeft: "5%" }}>
          d ={" "}
          <span style={{ borderBottom: "1px solid black" }}>
            <span className="modal-word" onClick={handleShow3}>
              OP
            </span>{" "}
            -{" "}
            <span className="modal-word" onClick={handleShow4}>
              SP
            </span>{" "}
          </span>{" "}
          <span>&nbsp; x 100</span>
          <br />
          <span className="modal-word" onClick={handleShow3}>
            {" "}
            <span style={{ visibility: "hidden" }}>d=OP</span>OP
          </span>{" "}
        </div>
        <br />
        <i>
          Meaning: The "original price" minus the "sale price", divided by the
          "discount", and then multiplying the result by 100 (just move the dot
          2 times) equals the "discount" that was given
        </i>
        <br />
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Modal show={show1} onHide={handleClose1} size="md">
          <Modal.Header closeButton>
            <Modal.Title>What does this variable mean?</Modal.Title>
          </Modal.Header>
          <Modal.Body>OP stands for "original price"</Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2} size="md">
          <Modal.Header closeButton>
            <Modal.Title>What does this variable mean?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            "d" stands for "decimal" or "discount". Pretty much whatever % the
            problem is giving you
          </Modal.Body>
        </Modal>
        <Modal show={show3} onHide={handleClose3} size="md">
          <Modal.Header closeButton>
            <Modal.Title>What does this variable mean?</Modal.Title>
          </Modal.Header>
          <Modal.Body>"OP" stands for "Original Price"</Modal.Body>
        </Modal>
        <Modal show={show4} onHide={handleClose4} size="md">
          <Modal.Header closeButton>
            <Modal.Title>What does this variable mean?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            "SP" stands for "Sale Price", meaning: whatever price the product
            was actually sold for
          </Modal.Body>
        </Modal>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Reply this message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Jeremiah Jerry"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="You can reply here or text me, whatever makes your life easier :)"
                    required
                  />
                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        All the best, <br />
        <strong>Uncle Rafy</strong>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Jerry;
