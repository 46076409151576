import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import $ from "jquery";
import { CardBody, CardHeader, CardText } from "reactstrap";
import Bar from "./Bar";
import Footer from "./Footer";
import "./xylibrary.css";
const jsonData = require("./data.json");

const XyLibrary = () => {
  //////////state for data.json file//////////
  const [stateData, setStateData] = useState([]);
  const [links, setLinks] = useState([]);

  // console.log(stateData.map((item) => item.links && setLinks(item)));

  const getLinks = (stateData) => {
    let myLinks = [];

    const looping = stateData.map((item, i) => {
      let pushedLink = stateData.links;
      if (pushedLink) {
        setLinks(pushedLink);
      }
      return pushedLink;
    });
    return myLinks;
  };

  getLinks(stateData);

  console.log(links);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  /////////////////////////////////
  const alphabetized_all_items = stateData.sort((a, b) =>
    a.category.localeCompare(b.category)
  );

  const render_All_Items = alphabetized_all_items.map((item, i) => {
    return (
      <div key={i} className="col-md-4 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <a
              href={item.link}
              target="_blank"
              rel="noreferer"
              style={{ textDecoration: "none" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>{item.category}</strong>
                </p>
              </CardHeader>
            </a>
            <CardBody>
              <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //  ------------------------------ //
  const render_RacismClaims = stateData.map((item, i) => {
    if (item.category === "Racism-lies") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_EconomicClaims = stateData.map((item, i) => {
    if (item.category === "Economic claims") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //
  const render_HistoryRevisionism = stateData.map((item, i) => {
    if (item.category === "History revisionism") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_EnvironmentalClaims = stateData.map((item, i) => {
    if (item.category === "Environmental claims") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>{item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_HumanNatureClaims = stateData.map((item, i) => {
    if (item.category === "Human-Nature claims") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>{item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //
  const render_onChristianity = stateData.map((item, i) => {
    if (item.category === "Christianity") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  //  ------------------------------ //
  const render_SmearAndCancel = stateData.map((item, i) => {
    if (item.category === "Smear/Cancel") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_Topics = stateData.map((item, i) => {
    if (item.category === "Topics") {
      return (
        <div key={i} className="col-md-6 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 1.5%" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>On: {item.category}</strong>
                </p>
              </CardHeader>
              <CardBody style={{ padding: "3% 1.5%" }}>
                <CardText style={{ fontSize: "80%" }}>
                  <strong style={{ textAlign: "left" }}>
                    {item.backstory}
                  </strong>
                  <br />
                  <br />

                  <Row
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <Col style={{ flex: "1" }}>
                      {item.links.map((links, i) => {
                        return (
                          <ul
                            className="linksUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li key={i}>
                              <a href={links} target="_blank" rel="noreferrer">
                                <strong
                                  key={i}
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  Link
                                </strong>
                                <br />
                                <br />
                              </a>
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>

                    <Col style={{ flex: "9" }}>
                      {item.notes.map((note, i) => {
                        return (
                          <ul
                            className="notesUL"
                            style={{ borderBottom: "1px solid #D3D3D3" }}
                          >
                            <li
                              key={i}
                              style={{
                                textAlign: "left",
                                marginLeft: "-5%",
                              }}
                            >
                              {note}
                            </li>

                            <br />
                          </ul>
                        );
                      })}
                    </Col>
                  </Row>
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  // eslint-disable-next-line no-lone-blocks
  {
    /* Buttons funtions //////////////////////////////////////////////////////////////// */
  }

  const show_all = () => {
    document.querySelector("#all_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "All Items sorted by Category";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_racismLies = () => {
    document.querySelector("#racism_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "Claims of Racism";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_historyRevisionism = () => {
    document.querySelector("#history_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "History Revisionism";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_environmentalClaims = () => {
    document.querySelector("#environmental_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Environmental Claims (mostly from Nihilism...)";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_economicClaims = () => {
    document.querySelector("#economics_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Economic claims (mostly from Marx and Rosseau...)";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };
  //  ------------------------------ //

  const show_humanNatureClaims = () => {
    document.querySelector("#human_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Claims on Human Nature";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_christianityIdeas = () => {
    document.querySelector("#christianity_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Attacks on Christianity";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_smear = () => {
    document.querySelector("#smear_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Smear & Cancel (forced 'struggle sessions')";

    document.querySelector("#topics_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_topics = () => {
    document.querySelector("#topics_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "Topics";

    document.querySelector("#displayTitle").innerHTML = "Topics";
    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#economics_div").classList.add("hidden");
    document.querySelector("#racism_div").classList.add("hidden");
    document.querySelector("#environmental_div").classList.add("hidden");
    document.querySelector("#smear_div").classList.add("hidden");
    document.querySelector("#human_div").classList.add("hidden");
    document.querySelector("#history_div").classList.add("hidden");
    document.querySelector("#christianity_div").classList.add("hidden");
  };

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="xy_library">
        <br />
        <h4>X ≠ Y Library</h4>
        <br />
        <div style={{ textAlign: "center" }}>
          <Button
            size="sm"
            style={{ textAlign: "center" }}
            onClick={show_topics}
            className="altBlue"
          >
            X ≠ Y Topics
          </Button>
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <div className="btnContainer">
          <Button
            size="sm"
            onClick={show_christianityIdeas}
            className="altBlue"
          >
            On Christianity
          </Button>{" "}
          <Button
            size="sm"
            onClick={show_humanNatureClaims}
            className="altBlue"
          >
            On Human Nature
          </Button>{" "}
          <Button size="sm" onClick={show_all} className="altBlue">
            All Categories
          </Button>{" "}
          <Button size="sm" onClick={show_racismLies} className="altBlue">
            Racism Lies
          </Button>{" "}
          <Button
            size="sm"
            onClick={show_historyRevisionism}
            className="altBlue"
          >
            Revisionism
          </Button>{" "}
          <Button
            size="sm"
            onClick={show_environmentalClaims}
            className="altBlue"
          >
            Environmental Claims
          </Button>{" "}
          <Button size="sm" onClick={show_smear} className="altBlue">
            Smear & Cancel
          </Button>{" "}
          <Button size="sm" onClick={show_economicClaims} className="altBlue">
            Economics Claims
          </Button>{" "}
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <br />
        {/*  eslint-disable-next-line jsx-a11y/heading-has-content */}
        <h4 id="displayTitle"></h4>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <br />
        <Row id="all_div" className="generated_div hidden">
          {render_All_Items}
        </Row>
        {/* ------------------------------------ */}
        <Row id="topics_div" className="generated_div hidden">
          {render_Topics}
        </Row>
        {/* ------------------------------------ */}
        <Row id="racism_div" className="generated_div hidden">
          {render_RacismClaims}
        </Row>
        {/* ------------------------------------ */}
        <Row id="history_div" className="generated_div hidden">
          {render_HistoryRevisionism}
        </Row>
        {/* ------------------------------------ */}
        <Row id="environmental_div" className="generated_div hidden">
          {render_EnvironmentalClaims}
        </Row>
        {/* ------------------------------------ */}
        <Row id="smear_div" className="generated_div hidden">
          {render_SmearAndCancel}
        </Row>
        {/* ------------------------------------ */}
        <Row id="economics_div" className="generated_div hidden">
          {render_EconomicClaims}
        </Row>
        {/* ------------------------------------ */}
        <Row id="human_div" className="generated_div hidden">
          {render_HumanNatureClaims}
        </Row>
        {/* ------------------------------------ */}
        <Row id="christianity_div" className="generated_div hidden">
          {render_onChristianity}
        </Row>
        {/* ------------------------------------ */}
        {/* //////////////////////////////////////////////////////////////////////// */}
      </Container>
      <br />
      <br />
      <br />
      <br /> <br />
      <br />
      <br />
      <br />
      <br />
      <br /> <br />
      <Footer />
    </React.Fragment>
  );
};

export default XyLibrary;
