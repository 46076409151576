import React from "react";
import { Navbar } from "reactstrap";

const TheFooter = ({ imgSrc, textLeft, year }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div id="theFooter" style={{ marginTop: "auto" }}>
      <Navbar
        dark
        style={{
          background: "#040182",
          padding: "1em 4.5em",
          display: "flex",
          justifyContent: "space-between",
        }}
        expand="md"
        sticky={"bottom"}
      >
        <p
          className="footer-text-left"
          style={{ fontSize: "1em", color: "white" }}
        >
          {textLeft}
        </p>

        <div className="footer-text-right">
          <p
            className="footer-year"
            style={{
              color: "white",
              marginRight: "1em",
              display: "inline",
            }}
          >
            {year && <span>{currentYear}</span>}
          </p>

          {imgSrc && (
            <img
              alt=""
              className="footer-img"
              style={{ maxWidth: "3em" }}
              src={imgSrc}
            />
          )}
        </div>
      </Navbar>
    </div>
  );
};

export default TheFooter;
