import React, { useEffect } from "react";
import emailjs from 'emailjs-com';
import Bar from "./Bar";
import {
  Container,
  Row,
  Col,
  Card,
  Accordion
} from "react-bootstrap";
import './badPassword.css';
import EmailJS1 from "../EmailJS1";


const Z_BadPassword = () => {


  return (
    <React.Fragment>
      <Bar />

      <Container style={{ height: "100vh" }}>
        <br />
        <h3>Whoa! <br/>Something went wrong...</h3>
        <br />
        A couple of things may be happening here:
        <br />
        <br />
        <ul>
          <li>Either you entered the wrong password, or...</li>
          <li>I gave you a wrong password, or... 🤔</li>
          <li>You can try again by clicking{" "}
            <a href="https://ar-privatemessaging.com/">
              <b>here</b>
            </a>
            , or shoot me a message:
          </li>
        </ul>
        <Accordion className="accordion-with-EmailJ1 badpassword">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Message me &nbsp;
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>


                <EmailJS1 />
              
              
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </React.Fragment>
  );
};

export default Z_BadPassword;
