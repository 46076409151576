import React from "react";
import { Navbar, Nav, NavbarText } from "reactstrap";

const Footer = (props) => {
  return (
    <React.Fragment>
      <Navbar
        dark
        style={{ background: "#040182" }}
        expand="md"
        sticky={"bottom"}
        className="myFooter"
      >
        <p
          className="footer-text"
          style={{ fontSize: "14px", marginLeft: "10%", color: "white" }}
        >
          Copyright Ⓒ Alfredo R. Pabon, {new Date().getFullYear()}
        </p>

        <Nav className="ml-auto" navbar></Nav>
        <NavbarText></NavbarText>
      </Navbar>
    </React.Fragment>
  );
};

export default Footer;
