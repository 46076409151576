import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const ThePopComment = ({ showContent, popContent, trigger }) => {
  const [isMobile, setIsMobile] = useState(false);

  // use window to check if user is on mobile
  const checkIfMobile = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  //   console.log("isMobile", isMobile);

  const response = (
    <Popover>
      <Popover.Content style={{ background: "azure" }}>
        {popContent}
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <div className="">
        <OverlayTrigger
          trigger={isMobile ? "click" : "hover"}
          placement="right"
          overlay={response}
          rootClose="true"
        >
          <p style={{ marginY: "0" }} className="highlight-on-hover-text">
            {showContent}
          </p>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default ThePopComment;
