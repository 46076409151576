import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Card } from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import Bar from "./Bar";
import Footer from "./Footer";
import "./amorPropio.css";

import jsonDataFile from "./data.json";

const jsonData = jsonDataFile[0].firstArrayOfJsonDataFile;

const MorPropio = () => {
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-3 col-sm-6">
        <Fade>
          <a href={item.url} target="_blank" style={{ textDecoration: "none" }}>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <Card.Img
                variant="top"
                src={item.thumbnail}
                style={{ width: "100%", height: "200px" }}
              />

              <CardBody>
                <CardText style={{ textAlign: "left", color: "black" }}>
                  {item.description}
                </CardText>
              </CardBody>
            </Card>
          </a>
        </Fade>
      </div>
    );
  });

  return (
    <React.Fragment>
      <Bar />
      <Container id="amorPropio">
        <br />

        <h4>Dinamycally Generated Items</h4>
        <Row>{renderItems}</Row>

        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default MorPropio;

//just new

//asiados:
//https://www.xvideos.com/video29399559/african_guy_fucking_china_girlfriend
//
//https://www.pornhub.com/view_video.php?viewkey=ph610e0d30e6235

// https://www.xvideos.com/video19938101/massive_cock_riding

//eboniados new
//https://www.xvideos.com/video28394037/fuck_me_before_my_husband_gets_back-mzansisexblog.wordpress.com
//https://www.xvideos.com/video40778107/african_girl_fucked
//https://www.xvideos.com/video58321533/xt_-_real_orgasm_sexy_ebony_girl_moans_loudly_and_cums_super_hard_in_missionary_sex_
// https://www.xvideos.com/video1620098/smashing_the_home_girl

//
