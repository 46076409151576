import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  OverlayTrigger,
  Popover,
  Accordion,
} from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./interviewing.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const Interviewing = () => {
  ////////////modals for cards////////////
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="interviewing">
        <br />
        <h4>Julita julita,</h4>
        <br />
        Hoy día, las entrevistas de trabajo siguen una estructura compuesta de
        dos partes:
        <strong> Techincal Questions</strong> &{" "}
        <strong>Behavioral Questions</strong>.
        <br />
        <br />
        No puedo abundar en tus preguntas técnicas porque son "industry
        specific" but the "behavioral" ones are pretty{" "}
        <u>standard across all HR departments</u>. That's why the STAR method is
        so effective regardless of the professional field we are in 💪🏼
        <br />
        <br />
        Para ver las "behavioral questions" más populares que mis "career
        advirsors" recopilaron,{" "}
        <a
          href="https://docs.google.com/document/d/17E96jc2q1oZCWCfxtONhSuMN1y1yNdSwU5E4Z_lh7SQ/edit"
          target="_blank rel=noopener"
        >
          <strong>haz click aquí</strong>
        </a>
        . ⬅ That, is the most important link in all this message. Open it... use
        it 🙏🏽
        <br />
        <br />
        BEWARE these "behavioral questions" are crafted by HR professionals and{" "}
        <u>designed to trick interviwees</u> into revealing potentially damaging
        information they otherwise wouldn't volunteer, so{" "}
        <strong> having PRE-CANNED answers </strong> to these intentionally
        tricky questions (as opposed to trying to think/answer them "on the
        spot") is ESSENTIAL. The interview process is{" "}
        <a
          href="https://www.hrmorning.com/articles/why-the-interview-process-is-flawed/#:~:text=The%20flawed%20interview%20process%20evaluates,best%20fit%20for%20the%20job."
          target="_blank rel=noopener"
        >
          <strong>inherently flawed</strong>
        </a>{" "}
        (meaning: the most qualified individual is <strong>not</strong>{" "}
        necessarily the one getting the job 😔 ) so don't f*ck around with
        these: <u>write down your answers</u> + have a compelling story to tell
        + don't get caught unprepared. U got this 💪🏼 ♥️ .
        <br />
        <br />
        <br />
        {/* //////////////////////////////////////////////////////////////////////// */}
        <h4 style={{ marginBottom: "1em" }}>The S.T.A.R. Method</h4>
        This acronym stands for "<strong>S</strong>ituation", "
        <strong>T</strong>
        ask", "<strong>A</strong>ction" and "<strong>R</strong>esolution".
        Supongamos que te preguntan "
        <i>Tell us about a time you had a disagreement with a coworker?</i>"
        <br />
        <hr style={{ maxWidth: "30%" }} />
        <strong>
          S<span style={{ color: "grey" }}>ituation</span>
        </strong>
        : <span>¿Cuál fue el contexto?</span> <br />
        <i>
          "When I was working at {"{ some place }"} we had a very tight deadline
          for {"{ whatever }"} and {"{ whoever }"} ..."
        </i>
        <br />
        <br />
        <strong>
          T<span style={{ color: "grey" }}>ask</span>
        </strong>
        : ¿Qué había que hacer? <br />
        <i>
          "We/I absolutely needed to {"{ bla bla bla }"} in order to reach{" "}
          {"{ whatever goal }"} bla bla bla..."
        </i>
        <br />
        <br />
        <strong>
          A<span style={{ color: "grey" }}>ction</span>:{" "}
        </strong>{" "}
        <span>¿Qué hicíste?</span>
        <br />
        <i>
          "I took command/solved the situation by{" "}
          {
            "{ some action that display your awesome, wonderful skills... personal or techincal skillset, no importa }"
          }{" "}
          bla bla bla..."
        </i>
        <br />
        <br />
        <strong>
          R<span style={{ color: "grey" }}>esolution</span>
        </strong>
        : <span>¿Cuál fue el resultado? </span>
        <br />
        <i>
          "I got the problem solved by {"{ some happy resolution }"} and ever
          since, I learned {"{ some great learning/outcome you got}"} y desde
          entonces estoy más preparada para {"{ whatever problem }"} and ever
          since I poop nothing but potpourri-smelling roses with rainbow trails,
          y todo es bello."
        </i>
        <br />
        <hr style={{ maxWidth: "30%" }} />
        Aunque esa última parte may sound like an exageration, brings home the
        most important point here: <strong>do NOT</strong> share an experience
        that does not have a happy resolution/ending. If you dont have one, as
        much as I hate to say this, you might need to make one up before
        interview-day because your interwer dont have realiable methods to
        assess your character in a single meeting. Is therefore EXTREMELY
        important that you have pre-made answers to these tricky questions in
        which your "R" (resolution) has a <u>happy ending</u> where the{" "}
        <u>job got done</u>, issues got solved and <u>you LEARNED</u> something
        that makes you a better employee today. Please read that as many times
        as necessary.
        <br />
        <br />
        If you wait until interview-day to think an answer to these questions
        (the ones on the link I shared) you'll fail.
        <br />
        <br />
        <a href="https://www.youtube.com/watch?v=dWK26jZgsM8" target="_blank">
          <strong>This is an 8-minute</strong>
        </a>{" "}
        video talking about the STAR method (kinda borig, ngl) but{" "}
        <u>please listen to it</u> as it explains the process very well. After
        listening,
        <br />
        <ol style={{ marginTop: "1em" }}>
          <li>
            Go over <strong>the behavioral questions</strong> I shared in{" "}
            <a
              href="https://docs.google.com/document/d/17E96jc2q1oZCWCfxtONhSuMN1y1yNdSwU5E4Z_lh7SQ/edit"
              target="_blank rel=noopener"
            >
              <strong>the frist link</strong>
            </a>{" "}
            (minus question numbers 1,2,5 & 14){" "}
          </li>
          <li>
            Answer them using the star method <strong>in writinggggg</strong>
          </li>
        </ol>
        I'll add some ideas for question #22 when you are done with the first
        ones. Si haces esto no tengo duda alguna de que vás a salir bien.
        Escribe Julita... escribe como si estuvieras otra vez en la high 😂. The
        STAR method works wonders 🎉
        <br />
        <br />
        Dioj' mela ✝️ 🙏🏽
        <br />
        <strong>Alfredo Rafael Pabón</strong>
        <br />
        <br />
        <br />
        {/* 
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
            Commented text
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              
              
              <div className="commented-text">
              <OverlayTrigger
                trigger="click"
                placement="right"
                overlay={firstParagraph}
                rootClose="true"
              >
                <p className="highlight-on-hover-text">
                  <h4>Commented text</h4>
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                  iaculis et justo at bibendum. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Aliquam et dui ipsum. Integer tristique, quam in faucibus
                  convallis, nulla leo iaculis magna, sit amet mollis nulla eros in
                  orci. Fusce dapibus eget orci rutrum vulputate. Etiam eu dolor
                  quis elit egestas gravida. Suspendisse faucibus lorem enim, non
                  elementum mauris dapibus ut.
                </p>
              </OverlayTrigger>
              </div>
              
              
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Non Commented Text
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              
             
              <div className="">
              
                <p className="">
                  <h4>Text</h4>
                  <br />
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                  iaculis et justo at bibendum. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos.
                  Aliquam et dui ipsum. Integer tristique, quam in faucibus
                  convallis, nulla leo iaculis magna, sit amet mollis nulla eros in
                  orci. Fusce dapibus eget orci rutrum vulputate. Etiam eu dolor
                  quis elit egestas gravida. Suspendisse faucibus lorem enim, non
                  elementum mauris dapibus ut.
                </p>
              </div>
        
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
 
        
        <br />





*/}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Reply this message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Interviewing/Julita"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="Your text goes here"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Interviewing;
