import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
  Carousel
} from "react-bootstrap";

import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./vargheseMeeting.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const VargheseMeeting = () => {
  //////////Function for Carousel/////////
  function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    return (
      <Carousel activeIndex={index} onSelect={handleSelect}>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/Group-2-scaled.jpeg"
            alt="Group picture"
          />
          <Carousel.Caption>
            <p> ♥️ </p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/George-Chako-Gould-Table-1-scaled.jpeg"
            alt="George Chako Gould"
          />

          <Carousel.Caption>
            <p>Mary, Freddie, Aji, Vincent & Amber</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/Varghese-Chako-scaled.jpeg"
            alt="3rd slide"
          />
          <Carousel.Caption>
            <p>Varghese & Chako</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/IMG_5195-scaled.jpeg"
            alt="4th slide"
          />
          <Carousel.Caption>
            <p>Varghese & Pabon</p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/Young-table-scaled.jpeg"
            alt="5th slide"
          />
          {/* //////////////////////////////////////////////////////////////////////// */}
          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://rmrdetailing.com/wp-content/uploads/2022/03/Youth.png"
            alt="6th slide"
          />

          <Carousel.Caption>
            <p></p>
          </Carousel.Caption>
        </Carousel.Item>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://rmrdetailing.com/wp-content/uploads/2022/03/IMG_5209-scaled.jpeg"
          alt="7th slide"
        />
        <Carousel.Caption>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      {/* ////////////////////////////////////////////////////////////////////////// */}
      <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://rmrdetailing.com/wp-content/uploads/2022/03/FullSizeRender.jpeg"
        alt="8th slide"
      />
      <Carousel.Caption>
        <p>Auntie Anna & uncle Joseph with Johnny & Sunila</p>
      </Carousel.Caption>
    </Carousel.Item>
      {/* //////////////////////////////////////////////////////////////////////// */}
      <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://rmrdetailing.com/wp-content/uploads/2022/03/dfedfe5a-68cc-4083-a95e-3db271a8f524.jpg"
        alt="9th slide"
      />
      <Carousel.Caption>
        <p>Boston Joshua, Abigail, Chris, Andrew, Mathew, Gaby</p>
      </Carousel.Caption>
    </Carousel.Item>
      {/* //////////////////////////////////////////////////////////////////////// */}
      <Carousel.Item>
      <img
        className="d-block w-100"
        src="https://rmrdetailing.com/wp-content/uploads/2022/03/12920630-f734-4e41-b4e4-5fe399df5b27-1.jpg"
        alt="9th slide"
      />
      <Carousel.Caption>
        <p></p>
      </Carousel.Caption>
    </Carousel.Item>
      {/* //////////////////////////////////////////////////////////////////////// */}
      </Carousel>
    );
  }

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="Varghese Meeting">
        <br />
        <h4>Dear Family </h4>
        <i>Varghese, George, Gould, Mathew, Chacko, Pabón</i>
        <br />
        <br />
        Here you may find my pictures from last week's family
        gathering. If there is a picture/video you would like me to add here, feel
        free to send my way 😃 .
        <br />
        <br />
        Thank you Johnny and Sunila for organizing and making sure everything happen 🙌🏽 !
        <br/><br/>
        <strong>Sidescroll to see pictures</strong>. Since COVID didn't allow us to celebrate Boston Joshua's birthday, we took the opportunity to celebrate. For that video <a href='https://www.youtube.com/watch?v=vUiG7B7Fkjo' target='_blank'><strong>click here</strong></a> :)
        <br/><br/>
        <ControlledCarousel />
        <br />
        <br />
        Until soon!<br/>
        <strong>Alfredo Rafael</strong>
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Add pictures?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Varghese Family Meeting"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="To add pictures, send them to: alfredrafael@gmail.com. For questions, write them here!"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default VargheseMeeting;
