import React, { useState, useEffect } from "react";
import { Container, Row, Button, Card, Col } from "react-bootstrap";
import "./PAQs.css";
import Bar from "./Bar";
import Footer from "./Footer";

const PAQs = () => {
  return (
    <React.Fragment>
      <Bar />

      <Container id="">
        <div>
          <br />
          <h3>Potentially Asked Questions</h3>
          <br />
          <p>
            I created this website because when sharing information with family
            and friends, a web-format can be incredibly useful: it allows me to
            add pictures, animations, links and/or commentaries in a way that
            regular email (or social media posts) can't!
          </p>

          <p>
            This web-based format also allows me to update messages after they
            are sent. In addition, it saves you from scrolling your email inbox{" "}
            <i>ad-infinitum</i> to find an old message or social-media
            commentary I am sharing with you.{" "}
          </p>

          <p>
            Simply navigate to "ar-privatemessaging.com", enter the password I
            provided for you, and <i>Violá</i>! Here are a few privacy/technical
            questions people may have:{" "}
          </p>

          <Row className="cards PAQs-cards">
            <Card
              className="PAQs highlight-on-hover"
              style={{ width: "45%", margin: "1%" }}
            >
              <Card.Header>How did you build this website?</Card.Header>
              <Card.Body>
                <Card.Text>
                  <b>I build this with React.js</b>, hosted it on Netlify and
                  use Github for continuous development. All messages are
                  dynamically generated on the spot using React's virtual DOM.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card
              className="PAQs highlight-on-hover"
              style={{ width: "45%", margin: "1%" }}
            >
              <Card.Header>Who else can see these messages?</Card.Header>
              <Card.Body>
                <Card.Text>
                  <b>Nobody</b>, unless you give somebody else your password.
                  Messages are not available via search-engines and its Github
                  repository is private.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card
              className="PAQs highlight-on-hover"
              style={{ width: "45%", margin: "1%" }}
            >
              <Card.Header>
                How can I build one of these for myself?
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  <b>Any regular CMS</b> should be able to do a similar trick.
                  Mine is coded from scratch for privacy and flexibility but, as
                  long as you can control a page/blogpost searchablity and URL
                  you should be able to do it...
                </Card.Text>
              </Card.Body>
            </Card>

            {/* 
          <Card className="PAQs highlight-on-hover" style={{width: '45%', margin: '1%'}}>
            <Card.Header>How did you learn this?</Card.Header>
            <Card.Body>
              <Card.Text>
                After countless hours of studying this thing  I <b>became a developer</b> 
              </Card.Text>
            </Card.Body>
          </Card>           
           */}

            <Card
              className="PAQs highlight-on-hover"
              style={{ width: "45%", margin: "1%" }}
            >
              <Card.Header>
                How did you added the "message me" feature?
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  To allow users to send me messages directly from this
                  web-application I used{" "}
                  <a href="https://www.emailjs.com/" target="_blank">
                    <strong>EmailJS</strong>
                  </a>
                  , a <strong>library that allows you to connect </strong>your
                  React.js / Vue.js / Angular.js application to your email, for
                  free.
                </Card.Text>
              </Card.Body>
            </Card>
          </Row>
        </div>
      </Container>
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "10%" }}
      >
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default PAQs;
