import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  Accordion,
} from "react-bootstrap";
import { CardHeader, CardBody, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./smallweddingday.css";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const SmallWeddingDay = () => {
  //////////for data.json file//////////
  const jsonData = require("./data");

  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0", borderRadius: "10px" }}
          >
            <Card.Img
              variant="top"
              src={item.pictureUrl}
              style={{ borderRadius: "10px" }}
            />
          </Card>
        </Fade>
      </div>
    );
  });

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  ////////// start here ----------------//////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="propuestaMatrimonial">
        <br />
        {/* FROM JSON DATA //////////////////////////////////////////////////////////////////// */}
        <h4>Amada mía</h4>
        Te envío este link para que accedas las fotos de nuestra ceremonia
        pequeña. Todavía faltan varias because its a work in progress. Planeo
        crear una con las fotos del "engagement". Un beso!
        <br />
        <br />
        Con amor,
        <br />
        <strong>Alfredo Rafael</strong>
        <br />
        <Row>{renderItems}</Row>
        <br />
        <br />
        <br />
        {/* ////////////////////////////////////////////////////////// */}
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Send me a message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="GodGivenTribe"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="You can reply here, or text to my phone :)"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default SmallWeddingDay;
