import React from "react";
import { Row, Col } from "react-bootstrap";

const BigPictureOnSide = ({ imgSrc, alt, children, imgText, picSide }) => {
  return (
    <Row className="row-text-with-pic">
      {picSide === "left" && (
        <Col className="row-pic">
          <img src={imgSrc} alt={alt} />
          <p className="pic-text">{imgText}</p>
        </Col>
      )}

      <Col className="row-text">{children}</Col>

      {picSide === "right" && (
        <Col className="row-pic">
          <img src={imgSrc} alt={alt} />
          <p className="pic-text">{imgText}</p>
        </Col>
      )}
    </Row>
  );
};
export default BigPictureOnSide;
