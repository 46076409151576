import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  Card,
  OverlayTrigger,
  Popover,
  Accordion,
} from "react-bootstrap";
import { CardBody, CardHeader, CardText } from "reactstrap";
import emailjs from "emailjs-com";
import Bar from "./Bar";
import Footer from "./Footer";
import "./myles.css";
import EmailJS1 from "../EmailJS1";
import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

import jsonDataFile from "./data.json";

const jsonData = jsonDataFile[0].firstArrayOfJsonDataFile;

const Temporary = () => {
  ////////////modals for cards////////////
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //////////state for data.json file//////////
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  const renderItems = stateData.map((item, i) => {
    return (
      <div key={i} className="col-md-6 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <Card.Img variant="top" src={item.pictureUrl} />

            <CardHeader>
              <h5 style={{ textAlign: "left" }}>Backstory:</h5>
            </CardHeader>

            <CardBody>
              <CardText style={{ textAlign: "left" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //////////Function for EmailJS//////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message Sent");
  }

  //////////Explanations for Paragraphs /////////////

  const firstParagraph = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content style={{ background: "azure" }}>
        <strong>
          Response
          <br />
          <br />
          Suspendisse ut ipsum ut augue condimentum finibus eu in mauris. Proin
          tortor purus, aliquam a magna at, accumsan condimentum eros. Proin
          pulvinar purus eget nibh egestas, vel vestibulum nisi vehicula. Aenean
          vel convallis ex. Duis blandit diam ipsum, condimentum cursus justo
          ullamcorper id. Pellentesque scelerisque dictum lorem, id sollicitudin
          nibh vulputate quis. Praesent quis gravida velit.
        </strong>
      </Popover.Content>
    </Popover>
  );

  const secondParagraph = (
    <Popover id="popover-basic">
      {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
      <Popover.Content style={{ background: "azure" }}>
        <strong>
          Response <strong>two</strong>.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
          iaculis et justo at bibendum. Class aptent taciti sociosqu ad litora
          torquent per conubia nostra, per inceptos himenaeos. Aliquam et dui
          ipsum. Integer tristique, quam in faucibus convallis, nulla leo
          iaculis magna, sit amet mollis nulla eros in orci. Fusce dapibus eget
          orci rutrum vulputate. Etiam eu dolor quis elit egestas gravida.
          Suspendisse faucibus lorem enim, non elementum mauris dapibus ut.
        </strong>
      </Popover.Content>
    </Popover>
  );

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="myles" style={{ height: "100vh" }}>
        <br />
        <h4>Hello Myles</h4>
        <br />
        Since I acquire the super power of developing webpages out of thin air
        (and saving information in this format can be very useful) I'll
        gradually move our "data" email thread here 😃. I'll start with our last
        conversation a few weeks ago.
        <br />
        <br />
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              How bussinesses fail
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <h4>How bussinesses fail</h4>
                <p style={{ margin: "2% 0 0 1%" }}>
                  In one of our last conversations we talked about the
                  unexpected difficulties of entrepreneuring (I just made up
                  that verb, on the fly) and I shared two examples of how
                  crashed companies do so not necessarily because their product
                  quality, but because they <strong>fail to evovle</strong> when
                  circumstances require.
                  <br />
                  <br />
                  When Microsoft's Encarta approach to The Encyclopædia
                  Britannica to digitize their content, for example, the latter
                  replied: Listen, bro...
                  <i>
                    "the Encyclopædia Britannica has no plans to be on a home
                    computer. And since the market is so small, only 4 or 5
                    percent of households have computers, we would not want to
                    hurt our traditional way of selling."
                  </i>
                  <a
                    href="https://www.hbs.edu/ris/Publication%20Files/Reference%20Wars%20-%20Greenstein_6c4ac193-51eb-4758-a5a6-a4c412261411.pdf"
                    target="_blank"
                  >
                    <strong> Here is the data</strong>
                  </a>{" "}
                  pertaining this information.
                  <br />
                  <br />
                  The other example was how Kodak failed to transistion into the
                  digital format, sticking to their film-based model, and
                  eventually falling out of grace. We might say "Kodak moments",
                  still, the cameras in our hands dont have their logo.{" "}
                  <a href="link" target="_blank">
                    <strong>Here is an article</strong>
                  </a>{" "}
                  pertaining this piece of information.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Innocous, is not
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <h4>Innocous, is not</h4>
                <p style={{ margin: "2% 0 0 1%" }}>
                  For the last 7 years you have been appropiating my culture by
                  calling me "caballero". You might think is innocouls This is
                  obviously ccording to.
                  <br />
                  <br />
                  sdf
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              Cultural Appropiation
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <h4>Dont say 'caballero'</h4>
                <p style={{ margin: "2% 0 0 1%" }}>
                  For the last 7 years you have been appropiating my culture by
                  calling me "caballero". You might think is innocouls This is
                  obviously ccording to
                  <br />
                  <br />
                  sdf
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <i class="far fa-envelope"></i>{" "}
              &nbsp; Reply this message?
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  className=""
                  onSubmit={sendEmail}
                  style={{ width: "100%" }}
                >
                  <Form.Control
                    name="user_user"
                    as="input"
                    value="Myles Green JSX file"
                    style={{ display: "none" }}
                  />
                  <Form.Control
                    name="message"
                    as="textarea"
                    rows={6}
                    placeholder="Your text goes here"
                    required
                  />

                  <p class=""></p>
                  <Button className="sendButton" type="submit" value="Send">
                    Well, send it!
                  </Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <br />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Temporary;
