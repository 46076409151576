import React from "react";
import emailjs from "emailjs-com";
import { Form, Button } from "react-bootstrap";
import "./emailjs1.css";

import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";


export default function EmailJS1() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(MY_SERVICE_ID, "template_allData", e.target, MY_MAIL_USER_ID)
      .then(
        result => {
          console.log(result.text);
        },
        error => {
          console.log(error.text);
        }
      );

    e.target.reset();
    alert("Message sent! Thank you");
  }

  return (
    <Form className="" onSubmit={sendEmail} style={{ width: "100%" }}>
      <div className="emailjs1-form">
        <Form.Group
          name=""
          className="contact-info-emailjs1"
          style={{ flex: ".5", padding: "0 3% 0 0" }}
        >
          <Form.Label>
            <i class="fas fa-user"></i> Name
          </Form.Label>

          <Form.Control
            name="user_name"
            className="contact-info-slot name"
            type="text"
            required
            placeholder="Who is messaging :) "
          />

          <Form.Label>
            <i class="fas fa-envelope"></i> Email
          </Form.Label>
          <Form.Control
            name="user_email"
            className="contact-info-slot email"
            type="email"
            required
            placeholder="How do I reach back to you?"
          />

          <Form.Label>
            <i class="fas fa-phone-square-alt"></i> Number
          </Form.Label>
          <Form.Control
            name="user_phone"
            type="telephone"
            placeholder="This is optional"
          />
        </Form.Group>
        <Form.Group className="message-text-area-emailjs1">
          <Form.Label>Your message</Form.Label>
          <Form.Control
            name="message"
            className="text-area-slot"
            as="textarea"
            required
            rows={5}
            placeholder="I am listening..."
          />
          <p></p>
          <Button className="sendButton" type="submit" value="Send">
            Well, send it!
          </Button>
        </Form.Group>
      </div>
      <hr />
    </Form>
  );
}
