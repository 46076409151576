import React from "react";
import { Navbar, Nav, NavbarText } from "reactstrap";

const Footer = (props) => {
  return (
    <React.Fragment>
      <Navbar
        dark
        style={{ background: "#040182" }}
        expand="md"
        sticky={"bottom"}
      >
        <p
          className="footer-text"
          style={{ fontSize: "14px", marginLeft: "10%", color: "white" }}
        >
          Copyright Ⓒ Alfredo R. Pabon, 2021
        </p>

        <Nav className="ml-auto" navbar></Nav>
        <NavbarText>
          <img
            className="footer-img"
            style={{ maxWidth: "30%" }}
            src="https://rmrdetailing.com/wp-content/uploads/2021/10/AR-logo-white.png"
          />
        </NavbarText>
      </Navbar>
    </React.Fragment>
  );
};

export default Footer;
