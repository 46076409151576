import TheFooter from "../../elements/TheFooter";
import TheNavbar from "../../elements/TheNavbar";
import TheLink from "../../elements/TheLink";
import OuterContainer from "../../elements/OuterContainer";
import TheQuickEmail from "../../elements/TheQuickEmail";
import TheCard from "../../elements/TheCard";
import TheModal from "../../elements/TheModal";
import ThePictureOnSide from "../../elements/BigPictureOnSide";
import ThumbnailOnSide from "../../elements/ThumbnailOnSide";
import TheAccordion from "../../elements/TheAccordion";
import Fade from "react-reveal/Fade";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

const NoMaam = () => {
  return (
    <OuterContainer>
      <TheNavbar
        bgcolor="#040182"
        text={"No ma'am"}
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        // imgSrc={"../images/AR.png"}
        // imgSrc={
        //   "https://i.redd.it/snoovatar/avatars/98946c81-6a9d-41ee-86ed-f92203e800e3.png"
        // }
        replyButton={true}
        personalItems={true}
      />
      <Container id="">
        <br />
        Es más facil contestar esto aquí que en el text...
        {/* Juxtaposed text ////////////////////////////////////////////////////////// */}
        <br />
        <br />
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>You said...</h4>
            </div>
            <i>
              "...there is something you said the other day as well that makes
              me think. Yes you have had to make fixtures to your car several
              times. Yes you have had to make fixtures to your car several
              times… idk if that’s convenient for me"
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>I say...</h4>
            </div>
            No ma'am, that is not what I said 😂. I said that even the times I
            have have to fix something, the amount of money I pay{" "}
            <strong>would never compare</strong> to the cost that a car payment
            would. My only "frequent" car expenses are maintenance stuff
            (brakes, tires, oil change, etc.) that Raul knows how to make. I
            never said my repairs were "frequent". They are not...
          </Col>
        </Row>
        <hr />
        <Row style={{ justifyContent: "center" }}>
          {/* Col ////////////////////////////////////////////////////////// */}
          <Col style={{ fontSize: "90%" }}>
            <i>
              ... it’s a thin line of “pay the $500 on a car that I know has no
              major damages or things idk about because I’m the one who has had
              it, so I’m not constantly having to fix it” or “get a used car
              without know really what the issues may be and have to pay for
              damages that could cost plenty of money”
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            No ma'am, that's a{" "}
            <i>
              <strong>False Dichotomy</strong>
            </i>{" "}
            {/* <TheLink
              url="www.youtube.com/watch?v=gDfQHWQwJ8Q&t=11s"
              text={"false dichotomy"}
            /> */}
            because having a cash car ≠ comprar un limón 😂. We both drive cash
            cars (el Hyundai fueron $4,600...) and{" "}
            <strong>neither of them</strong> given us any (engine, transmission,
            cooling) issues. If you wanna own & drive a new car, which is the
            very definition of "living beyond your means", then fine! Just say
            "I wanna drive around a brand new car, like a rich boss people" y
            ya. Just beware that{" "}
            <TheLink
              url="www.youtube.com/clip/UgkxJCpoRelY5QPuEdeHQfklnRfEiK58Mjqa?si=TNI2ryd3UiMEUHQQ"
              text="you're paying for that privilege"
            />{" "}
            with money you don't have.
          </Col>
          {/* Col ////////////////////////////////////////////////////////// */}
        </Row>
        <hr />
        <Row style={{ justifyContent: "center" }}>
          {/* Col ////////////////////////////////////////////////////////// */}
          <Col style={{ fontSize: "90%" }}>
            <i>
              "... vs the car I have now and know exactly what is happening with
              it and I can rely on it for my kids and work…”
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            No ma'am... if you are paying $500+ for a car, then there is only{" "}
            <u>one issue</u> that should be "happening with it", and that is{" "}
            <strong>none</strong>. Even if both of my cars start having issues
            tomorrow, the amount of money we have saved by not having a car
            payment would STILL be more than enough to fix them. <br />
            <br />
            And yes, although the "reliablity" argument seems honorable and all,
            context matters: your professional field of work is primarily{" "}
            <strong>remote</strong>, you have another adult with a(n even more
            expensive) car in the household, and as the lenght of your
            unemployment grows, that car is most of the time sitting there, not
            producing any income. So the "reliability" of the car is proably not
            as much as a deciding factor you think it is. Fix your relationship
            and{" "}
            <TheModal
              triggerElement="start working as a team"
              headerText={"And... no ma'am"}
            >
              <p>
                Before you hit me with the classic "Its not my fault, it's Raul!
                He is the source of all my problems and maladies" speech (a
                pretty consistent underlying argument), let us remember that (in
                front of Family, God and Court) you chose this man to be your
                husband until death do you part... so you better figure it out,
                somehow! But no ma'am, the car is not the issue. The issue is
                your relationship. Fix that, and you'll see how much money ya'll
                can save.
              </p>
            </TheModal>
            , and you'll see how much money ya'll can save.
          </Col>
          {/* Col ////////////////////////////////////////////////////////// */}
        </Row>
        <br />
        <hr />
        <div style={{ textAlign: "center" }}>
          If my analysis is missing data, please tell me what I missed{" "}
          <strong>logically</strong> (not emotionally). <br /> As soon as you
          read I can delete this private convo.
          <br />
        </div>
        <TheQuickEmail
          placeholder="Escríbeme aquí, o al email..."
          buttonText="Send it"
          from="No maam page"
        />
        <br />
      </Container>
      <TheFooter
        // imgSrc={
        //   "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        // }
        imgSrc={"../images/AR.png"}
        textLeft={"Developed with ❤️ by AR"}
        // year
      />
    </OuterContainer>
  );
};

export default NoMaam;
