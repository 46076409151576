import React, { useEffect, useState, useRef } from "react";
import Fade from "react-reveal/Fade";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import $ from "jquery";
import { CardBody, CardHeader, CardText } from "reactstrap";
import Bar from "./Bar";
import Footer from "./Footer";
import "./library.css";
const jsonData = require("./data.json");

const Library = () => {
  //////////state for data.json file//////////
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData(jsonData);
  }, []);

  /////////////////////////////////
  const alphabetized_all_items = stateData.sort((a, b) =>
    a.category.localeCompare(b.category)
  );

  const render_All_Items = alphabetized_all_items.map((item, i) => {
    return (
      <div key={i} className="col-md-4 col-sm-12">
        <Fade>
          <Card
            key={i}
            className="highlight-on-hover"
            style={{ margin: "3% 0" }}
          >
            <a
              href={item.link}
              target="_blank"
              rel="noreferer"
              style={{ textDecoration: "none" }}
            >
              <CardHeader style={{ paddingBottom: "-1%" }}>
                <p style={{ textAlign: "left", fontSize: "90%" }}>
                  &nbsp;&nbsp;<strong>{item.category}</strong>
                </p>
              </CardHeader>
            </a>
            <CardBody>
              <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                {item.backstory}
              </CardText>
            </CardBody>
          </Card>
        </Fade>
      </div>
    );
  });

  //  ------------------------------ //

  const render_Manosphere = stateData.map((item, i) => {
    if (item.category === "Manosphere") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>{item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_WorkSearch = stateData.map((item, i) => {
    if (item.category === "WorkSearch") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>{item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.company}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });
  //  ------------------------------ //

  const render_Education = stateData.map((item, i) => {
    if (item.category === "Education") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>{item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_Health = stateData.map((item, i) => {
    if (item.category === "Health") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>On {item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  //  ------------------------------ //

  const render_Constancia = stateData.map((item, i) => {
    if (item.category === "Constancia") {
      return (
        <div key={i} className="col-md-4 col-sm-12">
          <Fade>
            <Card
              key={i}
              className="highlight-on-hover"
              style={{ margin: "3% 0" }}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noreferer"
                style={{ textDecoration: "none" }}
              >
                <CardHeader style={{ paddingBottom: "-1%" }}>
                  <p style={{ textAlign: "left", fontSize: "90%" }}>
                    &nbsp;&nbsp;<strong>On {item.category}</strong>
                  </p>
                </CardHeader>
              </a>
              <CardBody>
                <CardText style={{ textAlign: "left", fontSize: "80%" }}>
                  {item.backstory}
                </CardText>
              </CardBody>
            </Card>
          </Fade>
        </div>
      );
    }
  });

  {
    /* Buttons funtions ////////////////////////////////////////////////////// */
  }

  const show_all = () => {
    document.querySelector("#all_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "All Items sorted by Category";

    document.querySelector("#manosphere_div").classList.add("hidden");
    document.querySelector("#health_div").classList.add("hidden");
    document.querySelector("#worksearch_div").classList.add("hidden");
    document.querySelector("#constancia_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_manosphere = () => {
    document.querySelector("#manosphere_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Data from the Manosphere";

    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#education_div").classList.add("hidden");
    document.querySelector("#health_div").classList.add("hidden");
    document.querySelector("#worksearch_div").classList.add("hidden");
    document.querySelector("#constancia_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_education = () => {
    document.querySelector("#education_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML =
      "Professional Education";

    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#manosphere_div").classList.add("hidden");
    document.querySelector("#health_div").classList.add("hidden");
    document.querySelector("#worksearch_div").classList.add("hidden");
    document.querySelector("#constancia_div").classList.add("hidden");
  };

  //  ------------------------------ //

  const show_health = () => {
    document.querySelector("#health_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "On Health";

    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#education_div").classList.add("hidden");
    document.querySelector("#manosphere_div").classList.add("hidden");
    document.querySelector("#worksearch_div").classList.add("hidden");
    document.querySelector("#constancia_div").classList.add("hidden");
  };

  const show_workSearch = () => {
    document.querySelector("#worksearch_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "Work Search";

    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#education_div").classList.add("hidden");
    document.querySelector("#manosphere_div").classList.add("hidden");
    document.querySelector("#health_div").classList.add("hidden");
    document.querySelector("#constancia_div").classList.add("hidden");
  };

  const show_constancia = () => {
    document.querySelector("#constancia_div").classList.remove("hidden");
    document.querySelector("#displayTitle").innerHTML = "Constancia";

    document.querySelector("#all_div").classList.add("hidden");
    document.querySelector("#education_div").classList.add("hidden");
    document.querySelector("#manosphere_div").classList.add("hidden");
    document.querySelector("#health_div").classList.add("hidden");
    document.querySelector("#worksearch_div").classList.add("hidden");
  };

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Bar />
      <Container id="library">
        <br />
        <h4>All Categories</h4>
        <br />
        {/* //////////////////////////////////////////////////////////////////////// */}
        <div className="btnContainer">
          <Button size="sm" onClick={show_all} className="altBlue">
            All Categories
          </Button>{" "}
          <Button size="sm" onClick={show_education} className="altBlue">
            Software Development
          </Button>{" "}
          <Button size="sm" onClick={show_workSearch} className="altBlue">
            WorkSearch
          </Button>{" "}
          <Button size="sm" onClick={show_health} className="altBlue">
            Health
          </Button>{" "}
          <Button size="sm" onClick={show_constancia} className="altBlue">
            Constancia
          </Button>{" "}
          {/* 
          <Button size="sm" className="altBlue">
            To reply (to add)
          </Button>{" "}
           */}
          <Button size="sm" className="altBlue" onClick={show_manosphere}>
            Manosphere
          </Button>{" "}
        </div>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <br />
        <h4 id="displayTitle"></h4>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <br />
        <Row id="all_div" className="generated_div hidden">
          {render_All_Items}
        </Row>
        {/* ------------------------------------ */}
        <Row id="manosphere_div" className="generated_div hidden">
          {render_Manosphere}
        </Row>
        {/* ------------------------------------ */}
        <Row id="education_div" className="generated_div hidden">
          {render_Education}
        </Row>
        {/* ------------------------------------ */}
        <Row id="health_div" className="generated_div hidden">
          {render_Health}
        </Row>
        <Row id="worksearch_div" className="generated_div hidden">
          {render_WorkSearch}
        </Row>
        <Row id="constancia_div" className="generated_div hidden">
          {render_Constancia}
        </Row>
        {/* //////////////////////////////////////////////////////////////////////// */}
      </Container>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br /> <br />
      <br /> <br />
      <Footer />
    </React.Fragment>
  );
};

export default Library;
