import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Form } from "react-bootstrap";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import "./myles.css";

import { MY_MAIL_USER_ID, MY_SERVICE_ID } from "../../config";

const Bar = (props) => {
  //////////for modal-mail/////////////////
  const [isOpen, setIsOpen] = useState(false);
  let toggleNavbar = () => setIsOpen(!isOpen);

  const { className } = props;

  //////////for collapsible navbar menu//////////

  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  /////////// for email /////////////////

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        MY_SERVICE_ID,
        "template_only_message",
        e.target,
        MY_MAIL_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
    toggleModal();
    alert("Got your message! Getting back to you ASAP");
  }

  //////////----------------/////////////---------------- /////////////

  return (
    <React.Fragment>
      <Navbar
        dark
        style={{ background: "#040182", borderBottom: "1px solid #040182" }}
        expand="md"
        sticky={"top"}
      >
        <Container>
          <NavbarBrand style={{ width: "50%" }} href="/">
            <img
              className="navbar-logo"
              src="https://rmrdetailing.com/wp-content/uploads/2021/10/AR-logo-white.png"
            />
            &nbsp; <span className="navbar-logo-text">My Messaging</span>
            {/* 
            <img
              className="navbar-logo myles"
              style={{ maxWidth: "5%", marginBottom: "2px" }}
              src="https://www.alfredorafael.com/wp-content/uploads/2021/04/Myles-Logo.png"
            />
            &nbsp; <span className="navbar-logo-text">Emilio Verde</span>

          */}
          </NavbarBrand>

          <NavbarToggler onClick={toggleNavbar} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/* 
             <NavItem>
                <NavLink href="/">
                  <i class="fas fa-home"></i>&nbsp; Home
                </NavLink>
              </NavItem>
            */}

              <NavItem>
                <NavLink href="/PAQs">
                  <i class="fas fa-question-circle"></i>&nbsp; PAQs
                </NavLink>
              </NavItem>

              <NavItem>
                <Button
                  className="navMessageButton"
                  style={{}}
                  onClick={toggleModal}
                >
                  <i class="fas fa-envelope"></i>&nbsp; Holla at Me
                </Button>
              </NavItem>
            </Nav>
            {/* 
          &nbsp; <NavbarText><span style={{color: 'white'}}>Text</span></NavbarText>
          */}
          </Collapse>
        </Container>
      </Navbar>

      <div>
        <Modal isOpen={modal} className={className}>
          <ModalBody>
            <Form className="" onSubmit={sendEmail} style={{ width: "100%" }}>
              <Form.Control
                name="user_user"
                as="input"
                value="Myles Green Page"
                style={{ display: "none" }}
              />
              <Form.Control
                name="message"
                as="textarea"
                rows={12}
                placeholder="Your message goes here :)"
              />
              <p class=""></p>
              <Button className="sendButton" type="submit" value="Send">
                Well, send it!
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button
                color="transparent"
                style={{ border: "1px solid #040182" }}
                onClick={toggleModal}
              >
                Cancel
              </Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Bar;

// <NavbarBrand href="/">
// <img src=""/>
// &nbsp; <span className="navbar-logo-text myles"><strong>Data</strong></span>
// </NavbarBrand>
