import TheFooter from "../../elements/TheFooter";
import TheNavbar from "../../elements/TheNavbar";
import TheLink from "../../elements/TheLink";
import OuterContainer from "../../elements/OuterContainer";
import TheQuickEmail from "../../elements/TheQuickEmail";
import TheCard from "../../elements/TheCard";
import TheModal from "../../elements/TheModal";
import ThePictureOnSide from "../../elements/BigPictureOnSide";
import ThumbnailOnSide from "../../elements/ThumbnailOnSide";
import TheAccordion from "../../elements/TheAccordion";
import Fade from "react-reveal/Fade";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";

const AKitchenSink = () => {
  return (
    <OuterContainer>
      <TheNavbar
        bgcolor="#040182"
        text={"Kitchen Sink"}
        imgSrc={
          "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        }
        // imgSrc={"../images/AR.png"}
        // imgSrc={
        //   "https://i.redd.it/snoovatar/avatars/98946c81-6a9d-41ee-86ed-f92203e800e3.png"
        // }
        replyButton={true}
        personalItems={true}
      />
      <Container id="generic">
        <br />
        <h4>Title Goes Here</h4>
        <br />
        Dear Kitchen Sink,
        <br />
        <br />
        <ThumbnailOnSide
          imgSrc={"http://placedog.net/200/200"}
          alt={"alt text"}
          right
          imgText={"Some text describing the pic"}
        >
          Starts here <TheLink url="www.google.com" text={"Lorem"} />
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Fuga deleniti quos excepturi quo consectetur totam consequuntur, odio
          voluptatem vel aperiam atque ducimus sit maxime ea. Mollitia cum illum
          asperiores molestias. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Fuga deleniti quos excepturi quo consectetur totam
          consequuntur, odio voluptatem vel aperiam atque ducimus sit maxime ea.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Fuga deleniti quos excepturi quo consectetur totam
          consequuntur, odio voluptatem vel aperiam atque ducimus sit maxime ea.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Fuga deleniti quos excepturi quo
          consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Fuga deleniti quos
          excepturi quo consectetur totam consequuntur, odio voluptatem vel
          aperiam atque ducimus sit maxime ea. Mollitia cum illum asperiores
          molestias. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Mollitia cum illum asperiores molestias. Lorem ipsum dolor sit amet
          consectetur adipisicing elit. Fuga deleniti quos excepturi quo
          consectetur totam consequuntur, odio voluptatem vel aperiam atque
          ducimus sit maxime ea. Mollitia cum illum asperiores molestias. Lorem
          ipsum dolor sit amet consectetur adipisicing elit. Ends here
        </ThumbnailOnSide>
        <br />
        {/* ACCORDIONS //////////////////////////////////////////////////////////////////// */}
        <h4>Accordion</h4>
        <TheAccordion cardHeader="Pulse aquí" number="0">
          <div>El primero</div>
        </TheAccordion>
        <TheAccordion cardHeader="Click me" number="1">
          <div>El numero dos</div>
        </TheAccordion>
        {/* EMAIL JS //////////////////////////////////////////////////////////////////// */}
        <TheQuickEmail
          buttonText="Send it"
          placeholder="Your message goes here"
          from="Generic page"
        />
        <br />
        <br />
        {/* CARDS  //////////////////////////////////////////////////////////////////// */}
        <h4>Cards</h4>
        <br />
        <Row className="cards">
          <Col>
            <TheCard headerText="Card 1">Card 1 Body</TheCard>
          </Col>

          <Col>
            <TheCard headerText="Card 2">Card 2 Body</TheCard>
          </Col>

          <Col>
            <TheCard headerText="Card 3">Card 3 Body</TheCard>
          </Col>
        </Row>
        <br />
        <br />
        {/* CARDS + MODALS //////////////////////////////////////////////////////////////////// */}
        <h4>Cards with modals</h4>
        <br />
        <Row className="modal-cards">
          <Col>
            <TheModal
              triggerElement={
                <TheCard
                  headerText="Card 1"
                  className="highlight-on-hover"
                  style={{ color: "black" }}
                >
                  Card 1 Body
                </TheCard>
              }
              headerText="Another Modal"
            >
              {/* Content inside the modal */}
              <p>This is another modal 1.</p>
            </TheModal>
          </Col>

          <Col>
            <TheModal
              triggerElement={
                <TheCard headerText="Card 1" className="highlight-on-hover">
                  Card 2 Body
                </TheCard>
              }
              headerText="Another Modal 2"
            >
              {/* Content inside the modal */}
              <p>This is another modal 2</p>
            </TheModal>
          </Col>

          <Col>
            <TheModal
              triggerElement={
                <TheCard headerText="Card 3" className="highlight-on-hover">
                  Card 3 Body
                </TheCard>
              }
              headerText="Another Modal 3"
            >
              {/* Content inside the modal */}
              <p>This is another modal 3</p>
            </TheModal>
          </Col>
        </Row>
        <br />
        <br />
        {/* Juxtaposed text ////////////////////////////////////////////////////////// */}
        <h3>Juxtaposed response</h3>
        <br />
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>Original</h4>
            </div>
            <i>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
              doloribus culpa qui vitae magni veniam nam possimus veritatis
              molestias amet est eaque numquam rerum ducimus nulla, eum in vero
              molestiae?
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            <div style={{}}>
              <h4>Response</h4>
            </div>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
            doloribus culpa qui vitae magni veniam nam possimus veritatis
            molestias amet est eaque numquam rerum ducimus nulla, eum in vero
            molestiae?
          </Col>
        </Row>
        <hr />
        <Row style={{ justifyContent: "center" }}>
          <Col style={{ fontSize: "90%" }}>
            <i>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
              doloribus culpa qui vitae magni veniam nam possimus veritatis
              molestias amet est eaque numquam rerum ducimus nulla, eum in vero
              molestiae?
            </i>
          </Col>
          <Col style={{ fontSize: "90%" }}>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores
            doloribus culpa qui vitae magni veniam nam possimus veritatis
            molestias amet est eaque numquam rerum ducimus nulla, eum in vero
            molestiae?
          </Col>
        </Row>
        <hr />
        <br />
      </Container>
      <TheFooter
        // imgSrc={
        //   "http://www.alfredorafael.com/wp-content/uploads/2019/02/whiteLogoName2.png"
        // }
        imgSrc={"../images/AR.png"}
        textLeft={"Developed with ❤️ by AR"}
        // year
      />
    </OuterContainer>
  );
};

export default AKitchenSink;
