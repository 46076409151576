import React from "react";

const OuterContainer = ({ id, children }) => {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          alignContent: "center",
        }}
        id={id}
      >
        {children}
      </div>
    </>
  );
};

export default OuterContainer;
