import React, { useState } from "react";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Row,
  Container,
} from "reactstrap";

const Bar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <Navbar
        dark
        style={{ background: "#040182", borderBottom: "1px solid #040182" }}
        expand="md"
        sticky={"top"}
      >
        <Container>
          <NavbarBrand href="/">
            <img
              className="navbar-logo"
              src="https://rmrdetailing.com/wp-content/uploads/2021/10/AR-logo-white.png"
            />
            &nbsp; <span className="navbar-logo-text">Rebecca Hailu</span>
          </NavbarBrand>

          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">Home</NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="/PAQs">PAQs</NavLink>
              </NavItem>
            </Nav>
            {/* 
          &nbsp; <NavbarText><span style={{color: 'white'}}>Text</span></NavbarText>
          */}
          </Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default Bar;
