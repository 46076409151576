import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/A1_EntryPoints";
import BadPassword from "./components/Z_BadPassword/Z_BadPassword";
import PAQs from "./components/PAQ/PAQ";
import Edglo from "./components/Edglo/Edglo";
import Motola from "./components/AbigailOmotola";
import Interviewing from "./components/Interviewing";
import VargheseMeeting from "./components/VargheseMeeting";
import PropuestaMatrimonial from "./components/PropuestaMatrimonial";
import SmallWeddingDay from "./components/SmallWeddingDay";
import AKitchenSink from "./components/AKitchenSink/AKitchenSink";
import NoMaam from "./components/NoMaam/NoMaam";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        {/* //////////////////////////////////////////////////////////////////////// */}
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/wrong-password" component={BadPassword} />
          <Route path="/PAQs" component={PAQs} />
          <Route path="/Edglo" component={Edglo} />
          <Route path="/Motola" component={Motola} />
          <Route path="/Interviewing" component={Interviewing} />
          <Route path="/FamMeeting" component={VargheseMeeting} />
          <Route path="/Proposal" component={PropuestaMatrimonial} />
          <Route path="/SmallWedding" component={SmallWeddingDay} />
          <Route path="/kitchenSink" component={AKitchenSink} />
          <Route path="/no-maam" component={NoMaam} />

          <Route
            render={() => (
              <h2 style={{ paddingTop: "15%" }}>404 Page not found</h2>
            )}
          />
        </Switch>
        {/* //////////////////////////////////////////////////////////////////////// */}
      </Router>
    </React.Fragment>
  );
};

export default App;
